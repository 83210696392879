<template>
  <!-- :class="{ 'is-home': isHome }" -->
  <div class="tabsbar select-none">
    <div class="placeholder-block"></div>
    <el-tabs 
      class="tabs layout-tabs"
      type="card"
      v-model="tabActive"
      @tab-remove="handleRemoveTab"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        v-for="route in visiteRoutes"
        :key="route.path"
        :label="route.meta.title"
        :name="route.path"
        :closable="!isAffix(route)"
      />
    </el-tabs>
    <el-dropdown @command="handleCommand" class="dropdown_tab">
      <span>
        更多操作
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="closeOtherTabs">
          <i class="el-icon-error"></i>
          关闭其他
        </el-dropdown-item>
        <el-dropdown-item command="closeAllTabs">
          <i class="el-icon-remove"></i>
          关闭全部
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'TabsBar',
    data() {
      return {
        tabActive: null,
      }
    },
    watch: {
      $route: {
        handler(route) {
          if (this.tabActive !== route.path) {
            this.tabActive = route.path
            this.setTab(route)
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapGetters({
        visiteRoutes: 'tabsBar/visiteRoutes',
        changePages: 'tabsBar/changePages',
      }),
      isHome() {
        return this.$route.path === '/index'
      },
    },
    methods: {
      ...mapActions({
        setTab: 'tabsBar/setTab',
        removeTab: 'tabsBar/removeTab',
        clearTab: 'tabsBar/clear',
        removeOtherTabs: 'tabsBar/removeOtherTabs',
        setChangePages: 'tabsBar/setChangePages',
      }),
      isAffix(tag) {
        return tag.meta?.affix
      },
      async handleRemoveTab(path) {
        const index = this.visiteRoutes.findIndex((item) => item.path === path)
        const delRoute = this.visiteRoutes[index]
        await this.$mgCloseEditPageConfirm(delRoute?.name)
        if (this.tabActive === path) {
          const currentRoute = this.visiteRoutes[index - 1] || this.visiteRoutes[index + 1]
          if (currentRoute) {
            if (this.tabActive !== currentRoute.path) {
              this.handleTabClick({ name: currentRoute.path })
            }
          }
        }
        this.removeTab(delRoute)
      },
      handleTabClick(tab) {
        if (this.$route.path !== tab.name) {
          const route = this.visiteRoutes.find((item) => item.path === tab.name)
          this.$router.push(route)
        }
      },
      handleCommand(command) {
        switch (command) {
          case 'closeOtherTabs':
            this.closeOtherTabs()
            break
          case 'closeAllTabs':
            this.closeAllTabs()
            break
        }
      },
      // 关闭其他标签
      async closeOtherTabs() {
        const changePages = this.changePages.filter((item) => item !== this.$route.name)
        if (changePages.length) {
          await this.$mgConfirm('有页面正在编辑，确认关闭?')
        }
        await this.removeOtherTabs(this.$route)
        this.setChangePages(this.changePages.includes(this.$route.name) ? [this.$route.name] : [])
      },
      // 关闭所有标签
      async closeAllTabs() {
        if (this.changePages.length) {
          await this.$mgConfirm('有页面正在编辑，确认关闭?')
        }
        await this.removeOtherTabs({ path: '/index' })
        this.$router.push({ path: '/index' })
        this.setChangePages([])
      },
    },
  }
</script>

<style lang="scss" scoped>
  // 90px = 【更多操作】的宽度
  // 20px = 右侧信息栏的marginLeft
  // 16px = 左侧菜单栏的marginRight
  .tabsbar {
    background: #fff;
    .tabs {
      position: fixed;
      left: 248px;
      right: 100px;
      top: 49px;
      z-index: 1;
    }
    &.is-home {
      .tabs {
        right: calc($mg-right-aside-width + $mg-body-offset + 20px + 90px);
      }
      .dropdown_tab {
        right: calc($mg-right-aside-width + $mg-body-offset + 20px);
      }
    }
    .dropdown_tab {
      position: fixed;
      z-index: 2;
      right: 7px;
      top: 56px;
      padding: 0 10px;
    }
    .placeholder-block {
      height: 42px;
    }
  }
  .layout-tabs {
    ::v-deep {
      .el-tabs__item {
        height: 32px;
        line-height: 32px;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 500;
        padding: 0 12px;
        border-left: none !important;
      }
      .el-tabs__item, .el-tabs__item:nth-child(2) {
        padding: 0 12px!important;
      }
      .el-tabs__item.is-active {
        color: #E60012;
      }
      .el-tabs__header {
        border-bottom: none;
      }
    }
  }
</style>
<style lang="scss" scoped>
  ::v-deep {
    .el-tabs__item.is-active, .el-tabs__item:hover {
      background: #FAFAFA;
    }
  }
  :deep(.el-tabs__header) {
    margin: 0;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border: none;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
    border-bottom: none;
  }
</style>
