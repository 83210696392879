import request from '@/utils/request'

/**
 * 获取携程订单信息
 * @param {*} data
 * @returns
 */
export function fetchCtripOrderInfo(data) {
  return request({
    url: '/mg/ecs-api/XcOrderInfo/detail',
    method: 'post',
    data,
  })
}

/**
 * 获取单据详情
 * @param {*} params
 * @returns
 */
export function fetchDetailById(params) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/detail',
    method: 'get',
    params,
    loading: true,
    loadingText: '数据加载中...',
  })
}

/**
 * 获取外勤单详情
 * @param {*} params
 * @returns
 */
export function fetchFieldOrderDetailByBillNo(params) {
  return request({
    url: '/mg/ecs-api/fieldworkApplyOrder/detail',
    method: 'get',
    params,
    loading: true,
    loadingText: '数据加载中...',
  })
}

/**
 * 保存or更新
 * @param {*} data
 * @returns
 */
export function saveOrUpdate(data) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/saveOrUpdate',
    method: 'put',
    data,
    loading: true,
  })
}
/**
 * 同步考勤数据
 * @param {*} data
 * @returns
 */
export function syncEhrData(data) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/syncEhr',
    method: 'post',
    data,
    loading: true,
  })
}

/**
 * 删除
 * @param {*} data
 * @returns
 */
export function deleteByBillNo(data) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/delete',
    method: 'delete',
    data,
    loading: true,
  })
}

/**
 * 作废
 * @param {*} data
 * @returns
 */
export function invalidByBillNo(data) {
  return request({
    url: '/mg/ecs-api/workflow/operation/invalidate',
    method: 'post',
    data,
    loading: true,
  })
}

/**
 * change
 * @param {*} data
 * @returns
 */
export function changeByBillNo(data) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/change',
    method: 'put',
    data,
    loading: true,
  })
}

/**
 * 查询差旅申请单列表
 * @param {*} data
 * @returns
 */
export function fetchTravelApplyOrderList(data) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/relatedQuery',
    method: 'post',
    data,
  })
}

/**
 * 查询外勤单列表
 * @param {*} data
 * @returns
 */
export function fetchFieldworkApplyOrderList(params) {
  return request({
    url: '/mg/ecs-api/fieldworkApplyOrder/list/canBeRelated',
    method: 'get',
    params,
  })
}

/**
 * 批量获取申请单明细
 * @param {*} data
 * @returns
 */
export function fetchTravelApplyOrderItemsByBillNos(data) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/relatedItem',
    method: 'post',
    data,
  })
}

/**
 * 获取工作流操作按钮
 * @param {*} data
 * @returns
 */
export function getFlowButton(data) {
  return request({
    url: '/mg/workflow-api/task/getFlowButton',
    method: 'post',
    data,
  })
}

/**
 * 获取单点登录参数
 * @returns
 */
export function getSingleLoginParam() {
  return request({
    url: '/mg/ecs-api/singleSignOn/pc/loginPage',
    method: 'get',
    loading: true,
    loadingText: '身份识别中...',
  })
}

/**
 * 通过付款公司查询承担主体
 * @returns
 */
export function getOrganizationListByCompany(data) {
  return request({
    url: '/mg/ecs-api/basicData/organization/query',
    method: 'post',
    data,
  })
}

/**
 * 冲借款信息
 * @param {*} data
 * @returns
 */
export function getDebtBookList(data) {
  return request({
    url: '/mg/ecs-api/debtBook/list',
    method: 'post',
    data,
  })
}

/**
 * 非商旅提交/保存
 * @param {*} data
 * @returns
 */
export function saveOrUpdateForNonBusinessTravel(data) {
  return request({
    url: '/mg/ecs-api/travelReimburseOrder/nonBusinessTravel/saveOrUpdate',
    method: 'put',
    data,
    loading: true,
  })
}

/**
 * 获取非商旅详情
 * @param {*} params
 * @returns
 */
export function getTravelReimburseOrderDetail(params) {
  return request({
    url: '/mg/ecs-api/travelReimburseOrder/detail',
    method: 'get',
    params,
    loading: true,
  })
}

/**
 * 财务稽核-同意
 * @param {*} data
 * @returns
 */
export function financialInspection(data) {
  return request({
    url: '/mg/ecs-api/travelReimburseOrder/nonBusinessTravel/financialInspection',
    method: 'put',
    data,
    loading: true,
  })
}

/**
 * 商旅结算单-编辑查询
 * @param {*} data
 * @returns
 */
export function businessTravelSettlementPage(data) {
  return request({
    url: '/mg/ecs-api/businessTravelSettlement/item/page',
    method: 'post',
    data,
  })
}

/**
 * 商旅结算单-编辑清空
 * @param {*} data
 * @returns
 */
export function businessTravelClear(billNo, itemTypeCode) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlement/clear/${billNo}/${itemTypeCode}`,
    method: 'get',
  })
}

/**
 * 商旅结算单-编辑保存
 * @param {*} data
 * @returns
 */
export function businessTravelSettlementSum(billNo) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlementSum/sum/${billNo}`,
    method: 'get',
  })
}

/**
 * 结算单列表
 * @param {*} data
 * @returns
 */
export function businessTravelSettlement(data) {
  return request({
    url: '/mg/ecs-api/businessTravelSettlement/page',
    method: 'post',
    data,
  })
}

/**
 * 结算单列表单条
 * @param {*} data
 * @returns
 */
export function businessTravelSettlementList(data) {
  return request({
    url: '/mg/ecs-api/businessTravelSettlement/list',
    method: 'post',
    data,
  })
}

/**
 * 商旅结算单-查看
 * @param {*} data
 * @returns
 */
export function getBusinessTravelSettlement(billNo) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlement/${billNo}`,
    method: 'get',
  })
}

/**
 * 商旅结算单-汇总查询
 * @param {*} data
 * @returns
 */
export function businessTravelSettlementSumForm(data) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlementSum/list`,
    method: 'post',
    data,
  })
}

/**
 * 商旅结算单-审核
 * @param {*} data
 * @returns
 */
export function putBusinessTravelSettlement(data) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlement`,
    method: 'put',
    data,
  })
}

/**
 * 商旅结算单-删除
 * @param {*} data
 * @returns
 */
export function deleteBusinessTravelSettlementSum(billNo) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlement/delData/${billNo}`,
    method: 'get',
  })
}

export function postExportExcel(data) {
  return request({
    url: '/mg/ecs-api/businessTravelSettlement/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

/**
 * 获取申请单扩展数据
 * @param {} data
 * @returns
 */
export function getApplyOrderExtras(params) {
  return request({
    url: '/mg/ecs-api/travelApplyOrder/travelExpansion',
    method: 'get',
    params,
  })
}

/**
 * 商旅结算单-单条编辑
 * @param {*} data
 * @returns
 */
export function itemEdit(data) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlement/item/edit`,
    method: 'post',
    data,
  })
}

/**
 * 商旅结算单-汇总查询
 * @param {*} data
 * @returns
 */
export function sumByType(data) {
  return request({
    url: `/mg/ecs-api/businessTravelSettlementSum/sumByType`,
    method: 'post',
    data,
  })
}

export function businessTravelSettlementSumExportExcel(data) {
  return request({
    url: '/mg/ecs-api/businessTravelSettlementSum/sumByType/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
