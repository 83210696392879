<script>
  import { FixedBar } from '@mg/mg-ui'
  export default {
    name: FixedBar.name,
    extends: FixedBar,
    computed: {
      bodyStyle() {
        const style = {
          left: this.left + 'px',
          width: `calc(100% - ${this.left + 0}px)`,
          zIndex: 10,
        }
        if (this.position === 'top')
          return {
            ...style,
            top: this.top + 'px',
          }
        return {
          ...style,
          bottom: 0,
        }
      },
    },
  }
</script>

<style></style>
