import request from '@/utils/request'

export function getFeeItemPage(data) {
  return request({
    url: '/mg/budget-api/feeItem/page',
    method: 'post',
    data,
  })
}

export function putFeeItem(data) {
  return request({
    url: '/mg/budget-api/feeItem',
    method: 'put',
    data,
  })
}

export function deleteFeeItem(data) {
  return request({
    url: '/mg/budget-api/feeItem',
    method: 'DELETE',
    data,
  })
}

export function getFeeItemItem(id) {
  return request({
    url: `/mg/budget-api/feeItem/${id}`,
    method: 'get',
  })
}

export function getFeeItemByCode(code) {
  return request({
    url: `/mg/budget-api/feeItem/code/${code}`,
    method: 'get',
    allowRepeat: true,
  })
}

export function getFeeTypeByCode(code) {
  return request({
    url: `/mg/budget-api/feeType/code/${code}`,
    method: 'get',
  })
}

/*
 * 树状图
 * */
export function getFeeTypeTreePage(data) {
  return request({
    url: '/mg/budget-api/feeType/tree',
    method: 'post',
    data,
  })
}

export function getFeeItemList(data) {
  return request({
    url: '/mg/budget-api/feeItem/list',
    method: 'post',
    data,
  })
}

export function postExportExcel(data) {
  return request({
    url: '/mg/budget-api/feeItem/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
