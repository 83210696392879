import request from '@/utils/request'

export function reimburseTypePage(data) {
  return request({
    url: '/mg/budget-api/reimburseType/page',
    method: 'post',
    data,
  })
}

export function reimburseTypePut(data) {
  return request({
    url: '/mg/budget-api/reimburseType',
    method: 'put',
    data,
  })
}

export function feeTypeList(data) {
  return request({
    url: '/mg/budget-api/feeType/list',
    method: 'post',
    data,
  })
}

export function getReimburseType(id) {
  return request({
    url: `/mg/budget-api/reimburseType/${id}`,
    method: 'get',
  })
}

export function reimburseTypeList(data) {
  return request({
    url: '/mg/budget-api/reimburseType/list',
    method: 'post',
    data,
  })
}

export function deleteTypeList(data) {
  return request({
    url: '/mg/budget-api/reimburseType',
    method: 'delete',
    data,
  })
}
