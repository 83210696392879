<script>
  import { Container } from '@mg/mg-ui'
  export default {
    name: Container.name,
    extends: Container,
  }
</script>

<style scoped lang="scss">
  .mg-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 176px);

    .table-content {
      flex: 1;
      height: 0;
      padding: 8px 8px 0;
      margin-top: 8px;
      background: white;
    }
  }
</style>
