import { TabsBarStorage } from '@/utils/storage'
const state = () => ({
  visiteRoutes: [],
  changePages: [],
})
const getters = {
  visiteRoutes: (state) => state.visiteRoutes,
  changePages: (state) => state.changePages,
}
const mutations = {
  initVisiteRoutes(state, visiteRoutes) {
    state.visiteRoutes = visiteRoutes
  },
  addVisiteRoutes(state, route) {
    state.visiteRoutes.push(route)
    TabsBarStorage.set(state.visiteRoutes.map((item) => item.path))
  },
  delVisiteRoutes(state, index) {
    state.visiteRoutes.splice(index, 1)
    TabsBarStorage.set(state.visiteRoutes.map((item) => item.path))
  },
  // 删除其他路由，只保留当前的路由
  delOtherVisiteRoutes(state, route) {
    state.visiteRoutes = state.visiteRoutes.filter((item) => item.path === '/index' || item.path === route.path)
    TabsBarStorage.set(state.visiteRoutes.map((item) => item.path))
  },
  setChangePages(state, changePages) {
    state.changePages = changePages
  },
  clear(state) {
    state.visiteRoutes = []
  },
}
const actions = {
  /**
   * 刷新网页时初始化Tab标签
   * @param {*} param0
   * @param {*} routes
   */
  initTab({ commit }, routes) {
    commit('initVisiteRoutes', routes)
  },
  /**
   * 设置路由对应的Tab标签,如果不存在则添加到visiteRoutes当中
   * visiteRoutes: 当前TabsBar对应的所有路由对象集合
   */
  setTab({ commit, state }, route) {
    const currentRoute = state.visiteRoutes.find((item) => item.path === route.path)
    if (!currentRoute && route.name !== '404') {
      commit('addVisiteRoutes', route)
    }
  },
  /**
   * 移除Tab
   * 如果移除的是当前Tab,则自动将临近右侧第一个设置为当前Tab
   * 否则将临近左侧第一个设置为当前Tab
   */
  removeTab({ dispatch, commit, state }, route) {
    const index = state.visiteRoutes.findIndex((item) => item.path === route.path)
    if (index !== -1) {
      commit('delVisiteRoutes', index)
      dispatch('removeChangePages', route)
    }
  },
  /**
   * 保存被编辑过的页面
   */
  setChangePages({ commit }, changePages) {
    commit('setChangePages', changePages)
  },
  // 移除被编辑过的页面
  removeChangePages({ dispatch, state }, route) {
    const changePages = state.changePages
    const index = changePages.findIndex((name) => name === route.name)
    if (index != -1) {
      changePages.splice(index, 1)
      dispatch('setChangePages', [...changePages])
    }
  },
  // 移除其他页面
  removeOtherTabs({ commit }, route) {
    commit('delOtherVisiteRoutes', route)
  },
  clear({ commit }) {
    commit('clear')
  },
}
export default { state, getters, mutations, actions }
