import { getGzipConfig } from '@/api/config'
const state = () => ({
  gzipConfig: null,
})
const getters = {
  gzipConfig: (state) => state.gzipConfig,
}
const mutations = {
  setGzipConfig(state, gzipConfig) {
    state.gzipConfig = gzipConfig
  },
}
const actions = {
  async getZipConfig({ commit }) {
    try {
      const { data: systemConfig } = await getGzipConfig()
      commit('setGzipConfig', systemConfig.gzipConfig)
    } catch (e) {
      console.error('系统配置接口‘/system/systemConfig’错误')
    }
  },
}
export default { state, getters, mutations, actions }
