<template>
  <div class="side-list">
    <div class="side-title">
      <img class="side-title-icon nav-avatar" :src="imgUrl" alt="avatar" />
      <span>{{ title }}</span>
    </div>
    <div class="side-content" v-for="(item, index) in sideList" :key="index">
      <dl>
        <dt style="width: 80px" class="sideContent-left">
          <img class="side-content-icon nav-avatar" :src="item.imgUrl" alt="avatar" />
          <span>{{ item.name }}</span>
        </dt>
        <dd>{{ item.num }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      sideList: {
        type: Array,
        default: () => [],
      },
      title: String,
      imgUrl: {
        type: String,
        default: '@/assets/layout/submit-process.png',
      },
    },
    data() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
  .side-list {
    font-family: PingFangSC, PingFang SC;
    border-radius: 4px;
    background: #fff;
    padding: 14px 24px 14px 14px;
    .side-title-icon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
    .side-content-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .side-title {
    font-family: PingFangSC-Semibold, PingFang SC;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  .side-content {
    margin-top: 16px;
  }
  dl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 13px;
    margin: 0;
  }
  dt {
    display: flex;
    align-items: center;
    //里面头对齐
    text-align: left;
    flex: 1;
    margin: 0;
    padding: 0;
    font-weight: 600;
    width: 80px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #666666;
  }
  dd {
    flex: 1;
    color: red;
    font-weight: 600;
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }
</style>
