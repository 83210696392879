import qs from 'qs'
import pako from 'pako'
import minimatch from 'minimatch'
import md5 from 'crypto-js/md5'
import store from '@/store'
import { cacheWhiteList, loadingText as loadingMsg, salt, tokenName } from '@/config'
import { HttpStatusCode } from '@/constants'
import { Loading } from 'element-ui'
import { TokenStorage } from '@/utils/storage'
// 添加签名
export function sign(config) {
  let sign = config.url
  if (config.method.toLocaleLowerCase() !== 'get' && config.data) {
    if (config.data !== null && typeof config.data === 'object') {
      config.data = JSON.stringify(config.data)
    }
    sign += config.data
  } else if (config.params !== null && typeof config.params === 'object') {
    sign += qs.stringify(config.params, { addQueryPrefix: true, skipNulls: true, encode: false })
  }
  // 去除换行/回车/空格/制表符
  sign = sign.replace(/\r|\n|\s|\t/g, '')
  sign = md5(sign + salt).toString()
  const timestamp = new Date().getTime()
  config.headers['x-timestamp'] = timestamp
  config.headers['x-sign'] = md5(sign + timestamp).toString()
  return config
}

// 添加请求头
export function addHeader(config) {
  const accessToken = TokenStorage.get()
  if (accessToken) {
    config.headers[tokenName] = accessToken
  }
  const belongId = store.getters['user/belongId']
  if (belongId) {
    config.headers[`x-csp-belongId`] = belongId
  }
  return config
}

// 压缩大数据请求体
export function gzipRequestBody(config) {
  try {
    const gzipConfig = store.getters['sys/gzipConfig']
    // 判断当前请求url是否需要压缩
    if (gzipConfig && gzipConfig?.matchUris.some((rule) => minimatch(config.url, rule))) {
      // 并且请求体大小是否超过 50kb(后台默认给的值)
      if (Buffer.from(config.data, 'utf-8').byteLength > 1024 * gzipConfig.thresholdKbSize) {
        config.headers['Content-Encoding'] = 'gzip'
        const bytes = pako.gzip(config.data)
        config.data = bytes
      }
    }
  } catch (error) {
    console.warn('压缩请求体失败')
  }
  return config
}

// 缓存接口请求数据
export async function addCache(config) {
  if (cacheWhiteList?.includes(config.url)) {
    // TODO 判断当前缓存是否过期，如果过期需要重新调用接口更新缓存
    const data = await LocalForage.getItem(config.url)
    if (data) {
      config.adapter = function (c) {
        return new Promise((resolve) => {
          resolve({
            config: c,
            data: {
              code: HttpStatusCode.CACHE_SUCCESS,
              data,
              msg: 'from cache',
            },
          })
        })
      }
    }
  }
  return config
}

// 显示全局loading
export function loading(config) {
  const { loading, loadingText } = config
  if (loading && !config.loadingInstance) {
    config.loadingInstance = Loading.service({ fullscreen: true, text: loadingText || loadingMsg })
  }
  return config
}
