<template>
  <el-main class="layout-main">
    <keep-alive :max="maxTabs" :include="cachedRoutes">
      <router-view :key="key" class="app-main" ref="main" />
    </keep-alive>
    <!-- iframe 页面 -->
    <component
      :is="FilialeReport"
      v-for="path in iframePaths"
      :key="path"
      v-show="path === key && isIframeRouterView"
    />
  </el-main>
</template>

<script>
  import { maxTabs, listeningPageChangeRouteNames } from '@/config'
  import { mapGetters, mapActions } from 'vuex'
  import FilialeReport from '@/views/report/filialeReport'
  export default {
    name: 'Main',
    data() {
      return {
        maxTabs,
        FilialeReport,
        iframePaths: [],
      }
    },
    computed: {
      ...mapGetters({
        visiteRoutes: 'tabsBar/visiteRoutes',
        changePages: 'tabsBar/changePages',
      }),
      cachedRoutes() {
        return this.visiteRoutes.reduce((cache, item) => {
          if (!item.meta.noKeepAlive) {
            cache.push(item.name)
          }
          return cache
        }, [])
      },
      key() {
        return this.$route.fullPath
      },
      // iframe 页面路由名称必须以 iframe- 开头
      isIframeRouterView() {
        return this.$route?.name?.toString().startsWith('iframe-')
      },
    },
    watch: {
      $route: {
        handler(route) {
          // 收集iframe页面
          if (this.isIframeRouterView) {
            if (!this.iframePaths.includes(route.fullPath)) {
              this.iframePaths.push(route.fullPath)
            }
          }
        },
        immediate: true,
      },
      changePages(newVal, oldVal) {
        if (oldVal.length === 0 && newVal.length) {
          window.addEventListener('beforeunload', this.handleBeforeunload)
          return
        }
        if (newVal.length === 0) {
          window.removeEventListener('beforeunload', this.handleBeforeunload)
        }
      },
    },
    mounted() {
      // 监听页面元素内容是否改变
      const change = () => {
        if (!listeningPageChangeRouteNames.includes(this.$route.name)) return
        if (!this.changePages.includes(this.$route.name)) {
          this.setChangePages([this.$route.name, ...this.changePages])
        }
      }
      this.$el.addEventListener('change', change)
      this.$once('hook:beforeDestroy', () => {
        this.$el.removeEventListener('change', change)
      })
    },
    methods: {
      ...mapActions({
        setChangePages: 'tabsBar/setChangePages',
      }),
      handleBeforeunload(event) {
        event.preventDefault()
        event.returnValue = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .layout-main {
    height: inherit;
    .app-main {
      box-sizing: border-box;
      // max-height: calc(100% - $mg-layout-tabsbar-height - 1px);
      height: calc(100vh - 102px);
      overflow: auto;
      // padding: 10px 0;
    }
  }
</style>
