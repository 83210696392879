<template>
  <transition name="msgbox-fade">
    <div class="el-message-box__wrapper ecs-message-box__wrapper" v-show="visible">
      <div class="el-message-box" :class="[customClass]">
        <div class="el-message-box__header">
          <div class="el-message-box__title">
            <span>{{ title }}</span>
          </div>
          <button
            type="button"
            class="el-message-box__headerbtn"
            aria-label="Close"
            @click="handleAction(distinguishCancelAndClose ? 'close' : 'cancel')"
            @keydown.enter="handleAction(distinguishCancelAndClose ? 'close' : 'cancel')"
          >
            <i class="el-message-box__close el-icon-close"></i>
          </button>
        </div>
        <div class="el-message-box__content">
          <div class="el-message-box__container">
            <div class="el-message-box__message" v-if="message !== ''">
              <slot>
                <p v-if="!dangerouslyUseHTMLString">{{ message }}</p>
                <p v-else v-html="message"></p>
              </slot>
            </div>
          </div>
        </div>
        <div class="el-message-box__btns">
          <el-button
            :loading="cancelButtonLoading"
            v-if="showCancelButton"
            size="small"
            @click.native="handleAction('cancel')"
            @keydown.enter="handleAction('cancel')"
          >
            {{ cancelButtonText }}
          </el-button>
          <el-button
            :loading="confirmButtonLoading"
            ref="confirm"
            v-show="showConfirmButton"
            size="small"
            type="primary"
            @click.native="handleAction('confirm')"
            @keydown.enter="handleAction('confirm')"
          >
            {{ confirmButtonText }}
          </el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    data() {
      return {
        action: '',
        title: null,
        visible: false,
        message: '',
        customClass: '',
        callback: null,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonLoading: false,
        confirmButtonLoading: false,
        dangerouslyUseHTMLString: false,
        distinguishCancelAndClose: false,
      }
    },
    methods: {
      handleAction(action) {
        this.action = action
        if (typeof this.beforeClose === 'function') {
          this.beforeClose(action, this, this.doClose)
        } else {
          this.doClose()
        }
      },
      doClose() {
        if (!this.visible) return
        this.visible = false
        setTimeout(() => {
          if (this.action) this.callback(this.action, this)
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .ecs-message-box__wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
  }
</style>
