import { getVehicleDict, getExhibitionTypeDict } from '@/api/basicData/dict'
const state = () => ({
  vehicleList: [],
  exhibitionTypeList: [],
})
const getters = {}
const mutations = {
  SET_VEHICLE_LIST(state, data) {
    state.vehicleList = data
  },
  SET_EXHIBITION_TYPE_LIST(state, data) {
    state.exhibitionTypeList = data
  },
}
const actions = {
  async FETCH_VEHICLE_LIST({ commit, state }) {
    if (state.vehicleList.length > 0) return { data: state.vehicleList }
    const { data } = await getVehicleDict()
    commit(`SET_VEHICLE_LIST`, data)
    return { data }
  },
  async FETCH_EXHIBITION_TYPE_LIST({ commit, state }) {
    if (state.exhibitionTypeList.length > 0) return { data: state.exhibitionTypeList }
    const { data } = await getExhibitionTypeDict()
    commit(`SET_EXHIBITION_TYPE_LIST`, data)
    return { data }
  },
}
export default { state, getters, mutations, actions }
