import request from '@/utils/request'
/**
 * 预算组织列表
 * @param {*} data
 * @returns
 */
export function getBudgetOrganizationList(data) {
  return request({
    url: '/mg/budget-api/budgetOrganization/list',
    method: 'post',
    data,
  })
}
/**
 * 预算项目分类（树形结构）
 * @param {*} data
 * @returns
 */
export function getBudgetProjectCategoryTree(data) {
  return request({
    url: '/mg/budget-api/budgetProjectCategory/getBudgetProjectCategorySubsetVO',
    method: 'post',
    data,
  })
}
/**
 * 预算项目列表查询
 * @param {*} data
 * @returns
 */
export function getBudgetProjectList(data) {
  return request({
    url: '/mg/budget-api/budgetProject/list',
    method: 'post',
    data,
  })
}

/**
 * 预算查询
 */
export function budgetQuery(data) {
  return request({
    url: `/mg/ecs-api/budget/query`,
    method: 'post',
    data,
  })
}
