// @ts-nocheck

/**
 * 延迟执行
 * @param {*} duration
 * @returns Promise
 */
export function delay(duration = 4) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

/**
 * 下载文件
 * @param {Blob} blob
 * @param {String} filename
 */
export function downloadFile(blob, filename) {
  const a = document.createElement('a')
  a.download = `${filename}`
  a.style.display = 'none'
  a.href = URL.createObjectURL(blob)
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

// 导出表格
export function excelExport(options) {
  return new Promise((resolve, reject) => {
    const { topHeaders, title, data } = options
    const { export_json_to_excel } = require('@/utils/excel/export2Excel') //这里必须使用绝对路径，使用@/+存放export2Excel的路径
    const headers = Object.values(topHeaders)
    const keys = Object.keys(topHeaders)
    const list = data.map((v) => keys.map((j) => v[j]))

    export_json_to_excel(headers, list, title) // 导出的表格名称，根据需要自己命名
    resolve(list)
  })
}

/**
 * 是否是微信环境
 */
export const isWXEnv = /wxwork/gi.test(navigator.userAgent)
