import request from '@/utils/request'

/**
 * 获取币种列表
 * @param {Object} data
 * @returns
 */
export function getCurrencyList(data) {
  return request({
    url: '/mg/drp-enterprise-api/currency/list',
    method: 'post',
    data,
  })
}

/**
 * 获取集团=MG的所有法人公司列表
 * @param {Object} data
 * @returns
 */
export function getPayCompanyPage(data) {
  return request({
    url: '/mg/fee-api/corporateCompany/pageMGCorporateCompany',
    method: 'post',
    allowRepeat: true,
    data,
  })
}

/**
 * 获取费用类型列表
 * @param {*} params
 * @returns
 */
export function getFeeTypeListByReimburseTypeCodeNoControl(params) {
  return request({
    url: '/mg/ecs-api/basicData/feeType/queryByReimburseTypeCode/noControl',
    method: 'get',
    params,
  })
}

/**
 * 根据报账类型获取费用类型列表
 * @param {*} params
 * @returns
 */
export function getFeeTypeListByReimburseTypeCode(params) {
  return request({
    url: '/mg/ecs-api/basicData/feeType/queryByReimburseTypeCode',
    method: 'get',
    params,
  })
}

/**
 * 查询承担主体
 * @param {*} data
 * @returns
 */
export function queryByAdministrativeCode(params) {
  return request({
    url: '/mg/ecs-api/basicData/organization/queryByAdministrativeCode',
    method: 'get',
    params,
    allowRepeat: true,
  })
}

/**
 * 分页查询承担主体
 * @param {*} params
 * @returns
 */
export function queryOrganizationCodePage(data) {
  return request({
    url: '/mg/ecs-api/basicData/organization/page',
    method: 'post',
    data,
    allowRepeat: true,
  })
}

/**
 * 获取人员档案信息 -- 分页
 * @param {*} params
 * @returns
 */
export function getStaffPage(data) {
  return request({
    url: '/mg/drp-user-api/mdStaff/pageStaffList',
    method: 'post',
    data,
    allowRepeat: true,
  })
}

/**
 * 根据员工获取门店信息
 * @param {*} params
 * @returns
 */
export function postStoresinfoByCodesLists(data) {
  return request({
    url: '/mg/drp-user-api/mdStaff/listStoresInfoByCodes',
    method: 'post',
    data,
  })
}

/**
 * 获取差旅标准列表
 * @param {*} data
 * @returns
 */
export function getTravelStandardsList(data) {
  return request({
    url: '/mg/ecs-api/travelStandards/list',
    method: 'post',
    data,
  })
}

/**
 * 获取一条差旅标准
 * @param {*} data
 * @returns
 */
export function getTravelStandardsOne(data) {
  return request({
    url: '/mg/ecs-api/travelStandards/getTravelStandardsOne',
    method: 'post',
    data,
    allowRepeat: true,
  })
}

/**
 * 批量获取差旅标准
 * @param {*} data
 * @returns
 */
export function getBatchTravelStandards(data) {
  return request({
    url: '/mg/ecs-api/travelStandards/getTravelStandardsAndCity',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {*} params
 * @returns
 */
export function postSupplierByFmsList(data) {
  return request({
    url: '/mg/fee-api/supplierRelation/queryByCodeOrNameSupplier',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {*} params
 * @returns
 */
export function postSupplierByFmsPage(data) {
  return request({
    url: '/mg/fee-api/supplierRelation/pageByCodeOrNameSupplier',
    method: 'post',
    data,
  })
}

// 通过付款公司编码获取银行账户 (目前已在还款单使用)
export function getByCorporateCompanyCodeAccountList(corporateCompanyCode) {
  return request({
    url: `/mg/fee-api/corporateCompanyAccount/queryByCorporateCompanyCode/${corporateCompanyCode}`,
    method: 'get',
  })
}

/**
 * 获取税率
 * @param {*} data
 * @returns
 */
export function getTaxItemsPage(data) {
  return request({
    url: '/mg/drp-enterprise-api/taxItems/page',
    method: 'post',
    data,
  })
}

/**
 * 通过供应商关系查询供应商关系的法人公司列表
 */
export function getCompanyListByRelation(data) {
  return request({
    url: '/mg/fee-api/corporateCompany/listBySupplierRelation',
    method: 'post',
    data,
  })
}

/**
 * 查询默认的承担主体
 */
export function getOrganizationDefault(params) {
  return request({
    url: '/mg/ecs-api/basicData/organization/default',
    method: 'get',
    params,
  })
}

/**
 * 预算查询
 */
export function budgetQuery(data) {
  return request({
    url: `/mg/ecs-api/budget/query`,
    method: 'post',
    data,
    loading: true,
  })
}

//获取关联的核销单
export function writeOffOrder(data) {
  return request({
    url: `/mg/ecs-api/writeOffOrder/getBillNo`,
    method: 'post',
    data,
  })
}

/**
 * 查询超标余额
 * @param {*} data
 * @returns
 */
export function fetchQuotaBalanceList(data) {
  return request({
    url: '/mg/ecs-api/quotaBalance/balanceQuery',
    method: 'post',
    data,
  })
}

/**
 * 查询合同列表信息
 * @param {*} data
 * @returns
 */
export function getContractList(data) {
  return request({
    url: '/mg/ecs-api/ecsReimburseContractRecord/getContractList',
    method: 'post',
    data,
  })
}

/**
 * 获取合同付款信息列表
 * @param {*} data
 * @returns
 */
export function getContractPaymentItemList(data) {
  return request({
    url: '/mg/ecs-api/ecsReimburseContractRecord/getContractPaymentItemList',
    method: 'post',
    data,
  })
}

/**
 * 获取合同跳转OA的地址
 * @param {*} data
 * @returns
 */
export function getOaUrl(data) {
  return request({
    url: '/mg/ecs-api/ecsContract/getOaUrl',
    method: 'post',
    data,
  })
}

/**
 * 获取申请单跳转OA的地址
 * @param {*} data
 * @returns
 */
export function getApplyOrderUrl(data) {
  return request({
    url: '/mg/ecs-api/applyOrder/url',
    method: 'post',
    data,
  })
}

//草稿态暂存
export function saveOrUpdateDraft(data) {
  return request({
    url: '/mg/ecs-api/draftOrder/saveOrUpdate',
    method: 'put',
    data,
    loading: true,
  })
}

//查询当前登录人下的草稿
export function getDraftDetail(id) {
  return request({
    url: `/mg/ecs-api/draftOrder/detail/${id}`,
    method: 'get',
    loading: true,
  })
}

/**
 * 保存草稿
 * @param {*} data
 * @returns
 */
export function saveDraft(data) {
  return request({
    url: '/mg/ecs-api/draftOrder/saveOrUpdate',
    method: 'put',
    loading: true,
    data,
  })
}

/**
 * 获取草稿详情
 * @param {*} draftOrderId
 * @returns
 */
export function getDraftDetailById(draftOrderId) {
  return request({
    url: `/mg/ecs-api/draftOrder/detail/${draftOrderId}`,
    method: 'get',
    loading: true,
  })
}
/**
 * 获取所有菜单地址
 * @param {*} params
 * @returns
 */
export function getPermissionsAllMenu(params) {
  return request({
    url: '/mg/drp-user-api/componentPermissionsMenu/list/all/v2',
    method: 'get',
    params,
  })
}

/**
 * 获取所有制度专栏
 * @param {*} params
 * @returns
 */
export function getInstitutional(data) {
  return request({
    url: '/mg/ecs-api/institutional/list',
    method: 'post',
    data,
  })
}


/**
 * 获取所有菜单地址
 * @param {*} params
 * @returns
 */
export function getShopPage(data) {
  return request({
    url: '/mg/fee-api/shop/page',
    method: 'post',
    data,
  })
}
