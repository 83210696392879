import request from '@/utils/request'
import { tokenName } from '@/config'

export function login(data) {
  return request({
    url: '/mg/drp-user-api/userAccount/login',
    method: 'post',
    data,
  })
}

export function getUserInfo(accessToken, type) {
  return request({
    url: `/mg/drp-user-api/employee/detail/${type}`,
    method: 'get',
    data: {
      [tokenName]: accessToken,
    },
  })
}

export function logout() {
  return request({
    url: '/mg/drp-user-api/userAccount/logout',
    method: 'get',
  })
}

export function register() {
  return request({
    url: '/register',
    method: 'post',
  })
}

// 修改密码
export function putChangePassWord(data) {
  return request({
    url: '/mg/drp-user-api/userAccount/changePassWord',
    method: 'put',
    data: data,
  })
}

//oa跳转登录
export function fkLogin(data) {
  return request({
    url: '/mg/drp-user-api/userAccount/fkLogin',
    method: 'post',
    data,
  })
}

/**
 * 获取员工信息
 * @param {*} data
 * @returns
 */
export function getMdStaffList(data) {
  return request({
    url: '/mg/drp-user-api/mdStaff/getStaffList',
    method: 'post',
    data,
    allowRepeat: true,
  })
}

/**
 * 获取员工信息 包括EHR
 * @param {*} data
 * @returns
 */
export function getMdStaff(data) {
  return request({
    url: '/mg/drp-user-api/mdStaff/pageStaffList',
    method: 'post',
    data,
  })
}

/**
 * 企微授权登录
 * @param {*} code
 * @returns
 */
export function wxAuthLogin(code, channel) {
  return request({
    url: '/mg/drp-user-api/userAccount/wxLogin',
    method: 'post',
    data: {
      code,
      channel,
    },
  })
}

/**
 * 根据用户所属中心编码查询付款公司
 * @param {*} code
 * @param {*} channel
 * @returns
 */
export function getPayCompanyByDeptCenterCode(deptCenrterCode) {
  return request({
    url: `/mg/ecs-api/orderPaymentConfig/${deptCenrterCode}`,
    method: 'get',
  })
}
