import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import store from '@/store'
import { maxTabs } from '@/config'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/Index.vue'),
        meta: { title: '首页', affix: true },
      },
    ],
    meta: { title: '首页', hidden: true },
  },
  {
    path: '/franchiseDepositRefundOrder',
    component: Layout,
    meta: { icon: '#', title: '加盟保证金退款单', hidden: true },
    children: [
      {
        path: '/franchiseDepositRefundOrder/audit/:billNo/:taskId',
        name: 'FranchiseDepositRefundOrderAudit',
        meta: { title: '审批加盟保证金退款单', hidden: true },
        component: () =>
          import('@/views/processNavigation/expenseReimbursement/franchiseDepositRefundOrder/components/audit'),
      },
      {
        path: '/franchiseDepositRefundOrder/result/:billNo/:taskId',
        name: 'FranchiseDepositRefundOrderResult',
        meta: { title: '结果加盟保证金退款单', hidden: true },
        component: () =>
          import('@/views/processNavigation/expenseReimbursement/franchiseDepositRefundOrder/components/result'),
      },
      {
        path: '/franchiseDepositRefundOrder/check/:billNo/:taskId',
        name: 'FranchiseDepositRefundOrderCheck',
        meta: { title: '稽核加盟保证金退款单', hidden: true },
        component: () =>
          import('@/views/processNavigation/expenseReimbursement/franchiseDepositRefundOrder/components/check'),
      },
    ],
  },
  {
    path: '/oaJumpLogin',
    name: 'oaJumpLogin',
    component: () => import('@/views/oaJumpLogin.vue'),
    meta: { title: 'oa跳转登录', hidden: true },
    props: (route) => ({
      token: route.query.token,
      ts: route.query.ts,
      loginId: route.query.loginId,
    }),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: '登录', hidden: true },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue'),
    meta: { title: '401', hidden: true },
  },
  {
    path: '/buttonJumpLogin',
    name: 'buttonJumpLogin',
    component: () => import('@/views/buttonJumpLogin.vue'),
    meta: { title: '按钮跳转登录', hidden: true },
    props: (route) => ({
      code: route.query.code,
      state: route.query.state,
      pathname: route.query.pathname,
    }),
  },
]

// 开发环境下，添加一些本地用于模拟测试的路由
// if (process.env.NODE_ENV === 'development') {
//   const mockRoutes = require('../../test/routes.js').default
//   constantRoutes.push(...mockRoutes)
// }

function createRouter() {
  return new VueRouter({
    routes: constantRoutes,
  })
}

const router = createRouter()

export function resetRouter() {
  router.matcher = createRouter().matcher
}

const originalPush = router.push
router.push = function (...args) {
  const location = this.match(args[0])
  const visiteRoutes = store.getters['tabsBar/visiteRoutes']
  const target = visiteRoutes.find((item) => item.path === location.path)
  if (!target && visiteRoutes?.length > maxTabs) {
    const message = `最多只能打开${maxTabs + 1}个页面，请先关闭部分页面。是否关闭前五个页面？`
    Vue.prototype
      .$mgAlert(message, '提示', { confirmButtonText: '关闭并打开新页面' })
      .then(() => {
        const changePages = store.getters['tabsBar/changePages']
        if (visiteRoutes.some((item) => changePages.includes(item?.name))) {
          return Vue.prototype.$mgConfirm('当前页面正在编辑，确认关闭?')
        }
      })
      .then(() => {
        visiteRoutes.slice(1, 5).forEach((route) => store.dispatch('tabsBar/removeTab', route))
        originalPush.call(this, ...args)?.catch(() => {})
      })
    return
  }
  return originalPush.call(this, ...args)?.catch(() => {})
}

export default router
