import request from '@/utils/request'

export function getFeeTypeList(data) {
  return request({
    url: '/mg/budget-api/feeType/list',
    method: 'post',
    data,
    allowRepeat: true,
  })
}

export function putFeeType(data) {
  return request({
    url: '/mg/budget-api/feeType',
    method: 'put',
    data,
  })
}

export function getFeeTypePage(data) {
  return request({
    url: '/mg/budget-api/feeType/page',
    method: 'post',
    data,
  })
}

export function getFeeTypeItem(id) {
  return request({
    url: `/mg/budget-api/feeType/${id}`,
    method: 'get',
  })
}

export function deleteFeeTypeItem(data) {
  return request({
    url: `/mg/budget-api/feeType`,
    method: 'DELETE',
    data,
  })
}

export function postExportExcel(data) {
  return request({
    url: '/mg/budget-api/feeType/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
