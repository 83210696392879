<template>
  <el-menu
    ref="menuRef"
    :default-openeds="defaultOpeneds"
    :router="true"
    :default-active="defaultActive"
    background-color="#fff"
  >
    <template v-for="menu in data">
      <SubMenu v-if="!isHidden(menu)" :menu="menu" :key="menu.path" />
    </template>
  </el-menu>
</template>

<script>
  import SubMenu from './submenu.vue'
  export default {
    name: 'Menu',
    components: { SubMenu },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      defaultOpeneds() {
        return ['/myProcess']
      },
      defaultActive() {
        return this.$route.path
      },
    },
    watch: {
      '$route.path': function (newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal === '/index' && this.$refs['menuRef']?.openedMenus) this.$refs['menuRef'].openedMenus = ['/myProcess']
        })
      }
    },
    methods: {
      isHidden(menu) {
        return menu?.meta?.hidden
      },
    },
  }
</script>
