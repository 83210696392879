import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import { routesWhiteList } from './setting.config'
import { TabsBarStorage, TokenStorage } from '@/utils/storage'
import { isWXEnv } from '@/utils'
NProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const accessToken = to.name == 'oaJumpLogin' ? '' : isWXEnv ? TokenStorage.get() : store.getters['user/accessToken']
  // DEBUG代码
  if (localStorage.getItem('TEST_MG_DEBUG') == 'DEBUG') {
    alert('path:' + to.path)
    alert('fullPath:' + to.fullPath)
    alert('TokenStorage:' + TokenStorage.get())
    alert('user/accessToken:' + store.getters['user/accessToken'])
    alert('accessToken:' + accessToken)
  }

  if (accessToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      return
    }
    // 是否已经生成权限路由
    const routes = store.getters['routes/routes']
    if (routes?.length || to.path === '/401') {
      next()
      return
    }

    // 获取路由权限数据
    await store.dispatch('user/getUserInfo')
    await store.dispatch('user/getPermissionsAllMenus')
    const permissions = store.getters['user/permissions']
    const permissionsAllMenus = store.getters['user/permissionsAllMenus']
    const workflowTabsBars =
      TabsBarStorage?.get()
        ?.map((path) => router.match(path)?.path)
        ?.filter((item) => item !== '/index') || []
    if (workflowTabsBars.length && permissionsAllMenus?.length) {
      const workflowConfigRoutes = await store.dispatch('routes/setWorkflowConfigRoutes', {
        routes: permissionsAllMenus[0].children,
        workflowRoutes: workflowTabsBars,
      })
      workflowConfigRoutes?.forEach((route) => router.addRoute(route))
    }

    if (permissions?.length) {
      const accessRoutes = await store.dispatch('routes/setRoutes', permissions[0].children)

      accessRoutes?.forEach((route) => router.addRoute(route))

      // 初始化TabsBar
      const routes = TabsBarStorage.get()?.map((path) => router.match(path))
      if (routes) store.dispatch('tabsBar/initTab', routes)
      if (to.path !== '/index') {
        store.dispatch('tabsBar/setTab', router.match('/index'))
      }
      next({ ...to, replace: true })
      return
    }
    // 用户无权限
    next({ path: '/401' })
    return
  }
  // 判断当前路由是否在白名单中
  if (routesWhiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    // 无 accessToken , 进入登录页
    next({ path: '/login', query: { redirect: to.path, wxRedirectUrl: to.fullPath } })
  }
})

router.beforeResolve((to, from, next) => {
  next()
  document.title = to?.meta?.title || process.env.VUE_APP_NAME
  NProgress.done()
})

router.afterEach((to) => {
  to.meta.matched = [...to.matched]
  handleKeepAlive(to)
})

function handleKeepAlive(to) {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      const element = to.matched[i]
      if (element.components.default.name === 'EmptyLayout') {
        to.matched.splice(i, 1)
        handleKeepAlive(to)
      }
    }
  }
}
