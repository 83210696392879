import { mapState } from 'vuex'
import router from '@/router'

/**
 * 关闭页面返回刷新列表
 */
export const CloseToRefreshList = {
  /**
   * 路由离开时，设置目标页是否需要刷新列表
   * @param {*} to
   * @param {*} from
   * @param {*} next
   */
  beforeRouteLeave(to, from, next) {
    const fromNeedRefreshList = from.meta?.needRefreshList
    // needRefreshList 没有定义在data中，是因为它只作为一个普通变量，无需响应式
    if (!this?.needRefreshList && !fromNeedRefreshList) return next()
    to.meta.needRefreshList = this?.needRefreshList || fromNeedRefreshList
    from.meta.needRefreshList = false
    next()
  },
  methods: {
    /**
     * 关闭当前页（跳转到指定页面）
     * @param {string | Location} to 可选参数,与路由对象的`push`方法入参一致，如果未指定 `to`,则默认打开前一个标签
     * @example
     * this.closeSelf('/xxx')
     * this.closeSelf(this.$route)
     * @returns
     */
    async closeSelf(to) {
      await this.$mgCloseEditPageConfirm(this.$route.name)
      const visiteRoutes = this.$store.getters['tabsBar/visiteRoutes']
      if (to && !(to instanceof Event)) {
        let location = this.$router.match(to)
        if (location.path !== this.$route.path) {
          const targetRoute = visiteRoutes.find((item) => item.path === location.path)
          if (targetRoute) location = targetRoute
          this.$store.dispatch('tabsBar/removeTab', this.$route)
          this.$router.push(location)
          return
        }
      }
      // 未指定目标路由走默认逻辑
      const index = visiteRoutes.findIndex((item) => item.path === this.$route.path)
      const toRoute = visiteRoutes[index - 1] || visiteRoutes[index + 1]
      if (toRoute) this.$router.push(toRoute)
      this.$store.dispatch('tabsBar/removeTab', visiteRoutes[index])
    },
  },
}

export const StoreState = {
  computed: {
    ...mapState({
      common: (state) => state.common,
      dict: (state) => state.dict,
    }),
  },
}

export const DynamicRoutingMixin = {
  methods: {
    /**
     * 不依赖侧边栏权限，根据工作流返回路由地址进行跳转
     * @param { String } routeUrl 工作流配置的路由
     * @returns 
     */
    handleWorkflowRoute({ routeUrl = '' } = {}) {
      return new Promise((resolve, reject) => {
        (
          async () => {
            try {
              if (!routeUrl) return this.$mgMessage('当前地址有误，请核对后重试', 'error')
              const matchRoute = this.$router.match(routeUrl)
              if (matchRoute.path === '/404') {
                const permissionsAllMenus = this.$store.getters['user/permissionsAllMenus']
                const workflowConfigRoutes = await this.$store.dispatch('routes/setWorkflowConfigRoutes', {
                  routes: permissionsAllMenus[0].children,
                  workflowRoutes: [routeUrl],
                })
                workflowConfigRoutes?.forEach((route) => router.addRoute(route))
              }
              await this.$router.push(routeUrl)
              resolve()
            } catch (error) {
              reject(error)
            }
          }
        )()
      })
    },
  }
}
