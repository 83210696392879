export function convertRouter(asyncRoutes) {
  return asyncRoutes.map((route) => {
    // component setting
    if (route.component) {
      route.componentLayout = null
      // first
      if (route.component === 'Layout') {
        route.componentLayout = 'Layout'
        route.component = (resolve) => require(['@/layout'], resolve)
        // seconed
      } else if (route.component === 'EmptyLayout') {
        route.componentLayout = 'EmptyLayout'
        route.component = (resolve) => require(['@/layout/EmptyLayout'], resolve)
      } else {
        const path = `views${route.component}`
        route.component = (resolve) => require([`@/${path}`], resolve)
      }
    } else {
      route.component = (resolve) => require(['@/layout/EmptyLayout'], resolve)
    }

    // iframe 页面 component 设置为空
    if (route.componentName?.startsWith('iframe-')) {
      route.component = null
    }

    // route recursion
    if (route.children && route.children.length) {
      route.children = convertRouter(route.children)
    }

    // delete [] route
    if (route.children && route.children.length === 0) {
      delete route.children
    }
    const routeIcon = route.icon || ''
    const activeIcon = (routeIcon && routeIcon.substring(0, routeIcon.length - 1)) || ''
    // return route
    return {
      name: route.componentName || route.menuId,
      path: route.path,
      component: route.component,
      componentLayout: route.componentLayout,
      children: route.children || [],
      menuId: route.menuId,
      meta: {
        title: route.menuName,
        icon: route.icon,
        activeIcon: activeIcon ? activeIcon + '2' : route.icon,
        extLink: route.extLink,
        docLink: route.docLink,
        buttons: route.buttons,
        hidden: route.hidden || false
      },
    }
  })
}

/**
 * 将按钮跳转的地址，添加到路由中
 * @param {*} convertRoutes
 */
export function addButtonRouter(convertRoutes, workflowRoutes = []) {
  return convertRoutes.reduce((buttonRoutes, route) => {
    if (route?.children?.length) {
      return buttonRoutes.concat(addButtonRouter(route.children, workflowRoutes))
    } else if (route?.meta?.buttons?.length) {
      const EmptyRoute = {
        path: '/' + route.name,
        component: (resolve) => require(['@/layout'], resolve),
        children: [],
        meta: {
          hidden: true,
        },
      }
      if (workflowRoutes?.length > 0) {
        workflowRoutes.forEach(workflowRoute => {
          const isCheckDetailPageHump = workflowRoute?.indexOf('checkDetail') > -1
          const isCheckDetailPageCutLine = workflowRoute?.indexOf('check-detail') > -1
          
          const roputeItem = route.meta.buttons.find(item => (item.component && item.componentPath) && ((isCheckDetailPageHump || isCheckDetailPageCutLine) ? (item.path === (isCheckDetailPageHump ? 'checkDetail' : 'check-detail')) : (workflowRoute?.indexOf(item?.componentPath?.split('/:')?.length > 0 ? item?.componentPath?.split('/:')[0] : null) > -1)))
          
          if (roputeItem) {
            const childrenRouteItem = handleAddEmptyChildrenRoute(roputeItem, route)
            EmptyRoute.children.push(childrenRouteItem)
          }
        })
      } else {
        route.meta.buttons.forEach((button) => {
          const buttonsRoute = button.component && button.componentPath
          if (buttonsRoute) {
            const childrenRouteItem = handleAddEmptyChildrenRoute(button, route)
            EmptyRoute.children.push(childrenRouteItem)
          }
        })
      }
      
      if (EmptyRoute.children.length) {
        buttonRoutes.push(EmptyRoute)
      }
      
    }
    return buttonRoutes
  }, [])
}

function handleAddEmptyChildrenRoute(roputeItem, route) {
  const childrenRouteItem = {
    path: roputeItem.componentPath,
    name: roputeItem.componentName,
    component: (resolve) => require([`@/views${roputeItem.component}`], resolve),
    meta: {
      name: roputeItem.componentName,
      title: roputeItem.menuName + route.meta.title,
      buttons: route.meta.buttons,
      noKeepAlive: false,
      hidden: true,
    },
  }
  return childrenRouteItem
}

