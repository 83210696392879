
<template>
  <el-menu-item v-if="!isHasChildren" :index="menu.path" class="truncate">
    <!-- 是否直接跳转外链 -->
    <template v-if="isExtLink">
      <div class="submenu-item-finalLevel" @click.stop="handleExtLinkClick">
        <mg-svg
          v-if="menuIcon"
          :svgClassName="activeMenus.includes(menu.path) ? menuActiveIcon : menuIcon"
          :svgStyle="{ width: '24px', height: '24px' }"
        />
        <span class="submenu-item-finalLevel--title" :title="menuName">{{ menuName }}</span>
      </div>
    </template>
    <template v-else>
      <div class="submenu-item-finalLevel">
        <mg-svg
          v-if="menuIcon"
          :svgClassName="activeMenus.includes(menu.path) ? menuActiveIcon : menuIcon"
          :svgStyle="{ width: '24px', height: '24px' }"
        />
        <span class="submenu-item-finalLevel--title" :title="menuName">{{ menuName }}</span>
      </div>
    </template>
  </el-menu-item>

  <el-submenu
    :class="['submenu-item', menu.componentLayout === 'Layout' ? 'submenu-item-layout' : 'submenu-item-emptyLayout']"
    v-else
    :index="menu.path"
  >
    <template slot="title">
      <div
        :class="menu.componentLayout === 'Layout' ? 'submenu-item-layout--title' : 'submenu-item-emptyLayout--title'"
      >
        <mg-svg
          v-if="menuIcon"
          :svgClassName="activeMenus.includes(menu.path) ? menuActiveIcon : menuIcon"
          :svgStyle="{ width: '24px', height: '24px' }"
        />
        <span class="submenu-item--title" :title="menuName">{{ menuName }}</span>
      </div>
    </template>
    <template v-for="submenu in menu.children">
      <SubMenu class="submenu-children--item" :menu="submenu" :key="submenu.path" v-if="!isHidden(submenu)" />
    </template>
  </el-submenu>
</template>

<script>
import { getSingleLoginParam } from '@/api/businessTrip'
import { CloseToRefreshList } from '@/mixins'
export default {
  name: 'SubMenu',
  props: {
    menu: Object,
  },
  computed: {
    isHasChildren() {
      return this?.menu?.children?.length > 0
    },
    menuName() {
      return this.menu?.meta?.title
    },
    menuIcon() {
      return this.menu?.meta?.icon
    },
    menuActiveIcon() {
      return this.menu?.meta?.activeIcon
    },
    isExtLink() {
      return this.menu?.meta?.extLink
    },
    ifCtrip() {
      return this.isExtLink == 'ctrip'
    },
    activeMenus() {
      return this.$route?.meta?.matched?.map((item) => item.path) || []
    },
  },
  components: {
    mgSvg: () => import('@/components/svg'),
  },
  mixins: [CloseToRefreshList],
  methods: {
    isHidden(menu) {
      return menu?.meta?.hidden
    },
    handleExtLinkClick() {
      if (this.ifCtrip) {
        getSingleLoginParam()
          .then((res) => {
            const html = res.data
            const blob = new Blob([html], { type: 'text/html' })
            const url = URL.createObjectURL(blob)
            window.open(url, '_blank')
          })
          .finally(() => {
            this.closeSelf('/')
          })
        return
      }
      window.open(this.isExtLink, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-submenu__title {
    margin-top: 10px;
  }
  .submenu-children--item {
    margin-top: 4px;
    border-radius: 4px;
  }
}

.submenu-item--title,
.submenu-item-finalLevel--title {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.submenu-item-layout--title {
  position: relative;
  background: linear-gradient(88deg, #f4feff 0%, #f4fff3 100%);
  padding: 10px 23px 10px 16px;
  .submenu-item--title {
    font-weight: bold;
  }
}
.submenu-item-layout--title::before {
  position: absolute;
  content: '';
  display: block;
  width: 4px;
  height: 100%;
  background: rgb(19, 71, 229, 0.54);
  left: 0;
  top: 0;
}
.submenu-item-layout--title,
.submenu-item-emptyLayout--title,
.submenu-item-finalLevel {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}
.submenu-item-emptyLayout {
  padding: 0 12px;
  ::v-deep {
    .el-submenu__title {
      border-radius: 4px;
    }
    .el-submenu__icon-arrow {
      right: 9px;
    }
  }
}
.submenu-item-emptyLayout.is-active {
  .submenu-item--title {
    color: #e52113;
  }
  ::v-deep {
    .el-submenu__icon-arrow {
      color: #e52113;
    }
  }
}
.submenu-item-emptyLayout--title {
  padding-left: 21px;
  .mg-svg-icon {
    margin-right: 5px;
  }
}
.submenu-item-finalLevel {
  padding-left: 31px;
  .mg-svg-icon {
    margin-right: 2px;
  }
}
</style>
