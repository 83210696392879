import request from '@/utils/request'

/**
 * 获取字典数据
 * 交通工具-JTGJ
 * 交通运费平台-YSFPT
 * 差旅级别-CLJB
 * 展会类型-ZHLX
 * 工作流_退回原因-GZL_THYY
 * 项目-XM
 */
export function getBasicDictDataByCode(code) {
  return request({
    url: `/mg/ecs-api/dict/item/${code}`,
    method: 'get',
  })
}
/**
 * 获取交通工具
 * @returns
 */
export function getVehicleDict() {
  return getBasicDictDataByCode('JTGJ')
}
/**
 * 获取展会类型
 * @returns
 */
export function getExhibitionTypeDict() {
  return getBasicDictDataByCode('ZHLX')
}

export function getRejectReasonList() {
  return getBasicDictDataByCode('GZL_THYY')
}
export function getProjectList() {
  return getBasicDictDataByCode('XM')
}
