<template>
  <div class="mg-virtual-table mg-vxe-table">
    <div class="mg-virtual-table-topbar" v-if="isShowTopBar">
      <div class="topbar-left">
        <slot name="topleft" />
      </div>
      <div class="topbar-right">
        <slot name="topright" />
        <template v-if="searchable">
          <el-button
            v-if="!showSearchBar"
            icon="iconfont mg-icon_sousuo_wubiankuang"
            size="mini"
            :disabled="false"
            @click="handleShowSearch"
            type="icon"
          />
          <el-button
            v-else
            plain
            :disabled="false"
            icon="iconfont mg-icon_sousuo_wubiankuang"
            size="mini"
            @click="handleShowSearch"
            type="icon"
          />
        </template>
        <el-button
          :disabled="false"
          icon="iconfont mg-icon_shezhi_wubiankuang"
          type="icon"
          @click="visible = true"
          v-if="configurable"
        />
        <mg-mini-pagination
          v-if="showMiniPagination"
          :page="page"
          :total="total"
          :total-page="totalPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>
    <!-- chronological 只对 multiple 有效，是否按照先后触发顺序进行排序 -->
    <div class="mg-virtual-table-container" :style="getTableContainerHeight">
      <vxe-table
        :class="['vxe-table-box', readonly ? 'readonly' : '']"
        v-loading="loading"
        border
        show-overflow="ellipsis"
        show-footer-overflow="ellipsis"
        ref="mgTable"
        auto-resize
        height="auto"
        size="mini"
        :sync-resize="true"
        show-header-overflow="title"
        :data="initialData || list"
        :scroll-y="{ mode: 'wheel', gt: 20 }"
        :scroll-x="{ gt: scrollXGt, enabled: scrollXEnable }"
        :filter-config="{ showIcon: false }"
        :checkbox-config="{
          highlight: true,
          checkRowKeys: checkRowKeys,
          checkMethod: this.checkMethod,
          ...$attrs['checkbox-config'],
        }"
        :sort-config="{
          iconAsc: 'el-icon-caret-top',
          iconDesc: 'el-icon-caret-bottom',
          remote: sortRemote,
          multiple: multipleSort,
          sortMethod: sortMethod,
          chronological: true,
        }"
        @sort-change="multiSortChange"
        :row-config="{ keyField: 'id', isHover: true, isCurrent: isCurrentRow }"
        :column-config="{ isCurrent: isCurrentColumn, resizable: true }"
        :seq-config="{ seqMethod: showPagination || showMiniPagination ? this.seqMethod : null }"
        :resizable-config="{ minWidth: 50 }"
        :keyboard-config="{ isEnter: true, isArrow: true, ...$attrs['keyboard-config'] }"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
        :mouse-config="{ selected: cellSelectedStatus }"
        @keydown="handleKeydown"
        @cell-selected="cellSelected"
        :footer-method="$attrs['footer-method'] || footerMethod"
        :row-class-name="handleRowClassName"
        :header-cell-class-name="calcCellName"
        @cell-click="handleCell"
        @checkbox-change="selectChangeEvent"
        @checkbox-all="selectAllEvent"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot name="checkbox"></slot>
        <!-- 自定义表格内容 -->
        <!--
				外面再包一层template是因为：
				columns添加可配置后，其依赖接口取值，
				而本地又有一份静态columns，导致缓存列的存在，
				对于下面默认插槽的渲染会走进该模板的渲染，导致dom渲染异常
			-->
        <template v-if="columns.length > 0">
          <template v-for="column in renderColumns">
            <!-- 表头分组	-->
            <mg-vxe-colgroup
              v-if="column.children && column.children.length > 0"
              :key="column.prop || column.type"
              :column="column"
              :show-search-bar="showSearchBar"
              :header-search="headerSearch"
              :filter-method="filterMethod"
            >
              <template #headerInputCol="{ scoped }">
                <el-input
                  :disabled="false"
                  v-if="headerSearch && scoped.item?.headerSearch"
                  v-model="filterForm[scoped.item.prop]"
                  @keyup.enter.native="handleEnterHeaderSearch"
                />
                <el-button
                  v-if="scoped.item?.type === 'seq'"
                  :disabled="false"
                  type="text"
                  size="mini"
                  @click="clearFilter"
                >
                  {{ $MGUI.i18n.t('重置') }}
                </el-button>
              </template>
              <template #headerSlotCol="item">
                <slot :name="item.headerSlot" v-bind="item"></slot>
              </template>
              <!-- slot 有值	-->
              <template #default="{ scoped }">
                <slot :name="scoped.item.slot" v-bind="{ ...scoped, index: scoped.$rowIndex }" />
              </template>
              <!-- default: true	-->
              <template #bodyContent="scoped">
                <slot :name="scoped.prop" v-bind="scoped"></slot>
              </template>
            </mg-vxe-colgroup>
            <template v-else>
              <mg-vxe-column
                v-if="column.slot && !column.hidden"
                :column="column"
                :key="column.prop"
                :show-search-bar="showSearchBar"
                :header-search="headerSearch"
                :filter-method="filterMethod"
              >
                <template #headerInput>
                  <el-input
                    :disabled="false"
                    v-if="headerSearch && column.headerSearch"
                    v-model="filterForm[column.prop]"
                    @keyup.enter.native="handleEnterHeaderSearch"
                  />
                  <el-button
                    v-if="column.type === 'seq'"
                    :disabled="false"
                    type="text"
                    size="mini"
                    @click="clearFilter"
                  >
                    {{ $MGUI.i18n.t('重置') }}
                  </el-button>
                </template>

                <template #headerSlot>
                  <slot :name="column.headerSlot"></slot>
                </template>
                <template #default="scoped">
                  <slot :name="column.slot" v-bind="{ ...scoped, index: scoped.$rowIndex }" />
                </template>
              </mg-vxe-column>
              <!-- 使用默认表格列渲染 -->
              <template v-else-if="column.default && !column.hidden">
                <slot :name="column.prop" v-bind="column" />
              </template>
              <!-- 常规数值显示的表格行 -->
              <mg-vxe-column
                v-else-if="!column.hidden"
                :column="column"
                :key="column.prop"
                :show-search-bar="showSearchBar"
                :header-search="headerSearch"
                :filter-method="filterMethod"
              >
                <template #headerInput>
                  <el-input
                    :disabled="false"
                    v-if="headerSearch && column.headerSearch"
                    v-model="filterForm[column.prop]"
                    @keyup.enter.native="handleEnterHeaderSearch"
                  />
                  <el-button
                    v-if="column.type === 'seq'"
                    :disabled="false"
                    type="text"
                    size="mini"
                    @click="clearFilter"
                  >
                    {{ $MGUI.i18n.t('重置') }}
                  </el-button>
                </template>
                <template #headerSlot>
                  <slot :name="column.headerSlot"></slot>
                </template>
              </mg-vxe-column>
            </template>
          </template>
        </template>
        <slot></slot>
      </vxe-table>
    </div>
    <el-pagination
      class="mg-pagination"
      v-if="showPagination"
      background
      :current-page="page"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pagination.size"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <mgColumnConfig
      v-if="visible"
      :visible.sync="visible"
      :columns.sync="renderColumns"
      @column-change="handleColumnChange"
    />
  </div>
</template>
<script>
  import { VxeTable } from '@mg/mg-ui'
  export default {
    name: VxeTable.name,
    extends: VxeTable,
    props: {
      isDisabledRow: {
        type: Boolean,
        default: false,
      },
      disabledCheckoutField: {
        type: String,
        default: 'selected',
      },
    },
    computed: {
      getTableContainerHeight() {
        return {
          height: `calc(100% - ${this.isShowTopBar ? '45px' : '10px'})`,
        }
      },
    },
    methods: {
      handleRowClassName(e) {
        if (this.$attrs['row-class-name']) {
          if (typeof this.$attrs['row-class-name'] === 'string') return this.$attrs['row-class-name']
          if (typeof this.$attrs['row-class-name'] === 'function') return this.$attrs['row-class-name'](e)
        }
        if (this.isDisabledRow && this.disabledCheckoutField && e.row[this.disabledCheckoutField])
          return 'current-row-disabled'
      },
      getRowIndex(row) {
        return this.$refs.mgTable.getRowIndex(row)
      },
    },
  }
</script>
<style>
  .mg-virtual-table-container {
    width: 100%;
    overflow: hidden;
  }
  .current-row-disabled {
    background: #f4f4f5 !important;
  }
  .current-row-disabled .vxe-icon-checkbox-checked {
    color: #d6d6d6 !important;
  }
</style>
