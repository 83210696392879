<script>
  import { UploadButton } from '@mg/mg-ui'
  import OSS from 'ali-oss'

  export default {
    name: UploadButton.name,
    extends: UploadButton,
    methods: {
      async handleRequestUpload(param) {
        this.$MGUI.utils
          .getAliyunToken({ originName: param.file.name })
          .then(({ data }) => {
            const client = new OSS({
              accessKeyId: data.accessKeyId,
              accessKeySecret: data.accessKeySecret,
              // 从STS服务获取的安全令牌（SecurityToken）。
              stsToken: data.securityToken,
              // 填写Bucket所在地域。以华东1（杭州）为例，设置region为oss-cn-hangzhou。
              region: 'oss-' + data.regionId,
              // 填写Bucket名称，例如examplebucket。
              bucket: data.bucketName,
            })
            let headers = {}
            if (param.file.type === 'text/plain') {
              headers['Content-Type'] = 'text/plain;charset=utf-8'
            }
            return client.multipartUpload(`${data.bucketDir}/${data.ossFileName}`, param.file, { headers }).then(() => {
              return {
                uid: param.file.uid,
                name: data.originName,
                ossName: data.ossFileName,
                url: data.bucketHost + data.bucketDir + '/' + data.ossFileName,
                remark: data.originName,
                appendixAddress: data.ossFileName,
              }
            })
          })
          .then((file) => {
            this.fileList.push(file)
            this.$emit('change', this.fileList)
          })
      },
    },
  }
</script>

<style></style>
