import request from '@/utils/request'
//当前用户信息流程查询
export function getqueryCurrentUserFlowInfo(data) {
  return request({
    url: '/mg/workflow-api/extUdInstanceOrderForm/queryCurrentUserFlowInfo',
    method: 'post',
    data,
  })
}
//待办任务
export function getnewTodoList(data) {
  return request({
    url: '/mg/workflow-api/task/myTodoPageList',
    method: 'post',
    data,
  })
}
//已办任务
export function getnewfinishedList(data) {
  return request({
    url: '/mg/workflow-api/task/myFinishedPageList',
    method: 'post',
    data,
  })
}
//我的申请
export function getApplyPageList(data) {
  return request({
    url: '/mg/workflow-api/task/myApplyPageList',
    method: 'post',
    data,
  })
}
//转发我的
export function getforwardMy(data) {
  return request({
    url: '/mg/workflow-api/task/myCarbonCopyPageList',
    method: 'post',
    data,
  })
}
export function getFlowIdentityLinks(data) {
  return request({
    url: '/mg/workflow-api/task/getFlowIdentityLinks',
    method: 'post',
    data,
  })
}
/**
 * 查询当前登录人下的草稿单
 * @param {*} params
 * @returns
 */
export function getDraftOrderList(data) {
  return request({
    url: '/mg/ecs-api/draftOrder/queryByCurrentUser',
    method: 'post',
    data,
  })
}
/**
 * 删除草稿
 * @param {*} draftOrderId
 * @returns
 */
export function deleteDraftById(draftOrderId) {
  return request({
    url: `/mg/ecs-api/draftOrder/${draftOrderId}`,
    method: 'delete',
  })
}

/**
 * 导出我的待办
 * @param {*} draftOrderId
 * @returns
 */
export function exportMyTodoList(data) {
  return request({
    url: `/mg/workflow-api/task/exportMyTodoList`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
/**
 * 导出我的已办
 * @param {*} draftOrderId
 * @returns
 */
export function exportMyFinishedList(data) {
  return request({
    url: `/mg/workflow-api/task/exportMyFinishedList`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
