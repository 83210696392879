<template>
  <el-aside class="layout-right-aside" width="200px">
    <div class="system-column">
      <div class="system-column-title">
        <div class="system-column-title-left">
          <img :src="systemImgUrl" alt="avatar" class="side-title-icon nav-avatar" />
          <span>制度专栏</span>
        </div>
        <div v-if="false" clas="system-column-title-right">
          <el-input
            v-model.trim="systemInfo"
            :style="{ width: systemInputWidth + 'px' }"
            class="search-input"
            clearable
            placeholder="搜索"
            @blur="handleSystemBlur"
            @focus="handleSystemFocus"
            @keydown.enter.native="handleSystemSearch"
          >
            <template v-if="!systemInfo" slot="append">
              <mg-svg
                :svgStyle="{ display: 'flex', width: '16px', height: '16px' }"
                svgClassName="mg-icon_search"
                @click="handleSystemSearch"
              />
            </template>
          </el-input>
        </div>
      </div>
      <ul class="system-column-content">
        <li v-for="(item, index) in specialList" :key="item.articleRoute + index" :title="item.articleTitle" class="system-column-item">
          <a :download="item.articleTitle" :href="item.articleRoute" target="_blank">
            <span class="system-column-item--label">{{ item.articleType }}</span>
            <span class="system-column-item--content">{{ item.articleTitle }}</span>
          </a>
        </li>
        <!--        <li class="system-column-item" title="费控管理系统培训手册">-->
        <!--          <a-->
        <!--            download="费控管理系统培训手册"-->
        <!--            href="https://mg-ecs-sh-oss.oss-cn-shanghai.aliyuncs.com/prod/%E8%B4%B9%E6%8E%A7%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F%E5%9F%B9%E8%AE%AD%E6%89%8B%E5%86%8C.pdf"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            <span class="system-column-item&#45;&#45;label">手册</span>-->
        <!--            <span class="system-column-item&#45;&#45;content">费控管理系统培训手册</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li class="system-column-item" title="费控报销指引24.5.7版">-->
        <!--          <a-->
        <!--            download="费用报销指引"-->
        <!--            href="https://mg-ecs-sh-oss.oss-cn-shanghai.aliyuncs.com/prod/%E8%B4%B9%E6%8E%A7%E6%8A%A5%E9%94%80%E6%8C%87%E5%BC%9524.5.7%E7%89%88.xlsx"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            <span class="system-column-item&#45;&#45;label">手册</span>-->
        <!--            <span class="system-column-item&#45;&#45;content">费控报销指引24.5.7版</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li class="system-column-item" title="费控系统常见问题答疑">-->
        <!--          <a-->
        <!--            download="费控系统常见问题答疑"-->
        <!--            href="https://mg-ecs-sh-oss.oss-cn-shanghai.aliyuncs.com/prod/%E8%B4%B9%E6%8E%A7%E7%B3%BB%E7%BB%9F%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98%E7%AD%94%E7%96%91.pdf"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            <span class="system-column-item&#45;&#45;label">手册</span>-->
        <!--            <span class="system-column-item&#45;&#45;content">费控系统常见问题答疑</span>-->
        <!--          </a>-->
        <!--        </li>-->
        <li v-if="false" class="system-column-item" title="费用报销的发票使用规范">
          <span class="system-column-item--label">规范</span>
          <span class="system-column-item--content">费用报销的发票使用规范</span>
        </li>
        <li v-if="false" class="system-column-item system-column-refresh">
          <i class="iconfont mg-icon_reset"></i>
          <span>换一批</span>
        </li>
      </ul>
    </div>
    <rightList
      :imgUrl="submitProcessUrl"
      :sideList="submitProcessArr"
      style="margin-bottom: 10px"
      title="我提交的流程"
    ></rightList>
    <rightList :imgUrl="myWorkUrl" :sideList="myWorkArr" style="margin-bottom: 10px" title="我的工作"></rightList>
    <rightList
      :imgUrl="todolistWarnUrl"
      :sideList="todolistWarnArr"
      class="todoListWarn"
      title="待办事项预警"
    ></rightList>
  </el-aside>
</template>

<script>
  import { getqueryCurrentUserFlowInfo } from '@/api/homePage/homePage.js'
  import rightList from './components/rightList.vue'
  import { mapGetters } from 'vuex'
  import { getInstitutional } from '@/api/common'

  export default {
    name: 'Right',
    components: {
      rightList,
      mgSvg: () => import('@/components/svg'),
    },
    data() {
      return {
        systemImgUrl: require('@/assets/layout/system-column.png'),
        myWorkUrl: require('@/assets/layout/my-work.png'),
        myWorkArr: [],
        todolistWarnUrl: require('@/assets/layout/todolist-warn.png'),
        todolistWarnArr: [],
        submitProcessUrl: require('@/assets/layout/submit-process.png'),
        submitProcessArr: [],

        systemInfo: '',
        systemInputWidth: 81,
        specialList: [],
      }
    },
    created() {
      this.getData()
    },
    computed: {
      ...mapGetters({
        employeeId: 'user/employeeId',
      }),
    },
    methods: {
      async getData() {
        this.todolistWarnArr = [
          {
            imgUrl: require('@/assets/layout/exceeding-days.png'),
            name: '超过1天',
            num: 0,
          },
          {
            imgUrl: require('@/assets/layout/exceeding-days.png'),
            name: '超过3天',
            num: 0,
          },
          {
            imgUrl: require('@/assets/layout/exceeding-days.png'),
            name: '超过5天',
            num: 0,
          },
        ]
        this.myWorkArr = [
          {
            imgUrl: require('@/assets/layout/backup-this-week.png'),
            name: '本周',
            num: 0,
          },
          {
            imgUrl: require('@/assets/layout/backup-this-month.png'),
            name: '本月',
            num: 0,
          },
          {
            imgUrl: require('@/assets/layout/backup-this-year.png'),
            name: '本年',
            num: 0,
          },
        ]
        this.submitProcessArr = [
          {
            imgUrl: require('@/assets/layout/backup-in-process.png'),
            name: '流程中',
            num: 0,
          },
          {
            imgUrl: require('@/assets/layout/archive-backup.png'),
            name: '已归档',
            num: 0,
          },
        ]
        await getqueryCurrentUserFlowInfo({
          userId: this.employeeId,
          tenant: 'FIN_ECS',
        }).then((res) => {
          res.data.map((item) => {
            this.todolistWarnArr.map((m) => {
              if (item.name == m.name) {
                m.num = item.num
              }
            })
            this.myWorkArr.map((m) => {
              if (item.name == m.name) {
                m.num = item.num
              }
            })
            this.submitProcessArr.map((m) => {
              if (item.name == m.name) {
                m.num = item.num
              }
            })
          })
        })
        //获取制度专栏
        await getInstitutional().then((res) => {
          this.specialList = res.data
        })
      },
      // 制度专栏 -- 查询
      handleSystemSearch() {},
      // 制度专栏 -- 聚焦
      handleSystemFocus() {
        this.systemInputWidth = 145
      },
      // 制度专栏 -- 失焦
      handleSystemBlur() {
        this.systemInputWidth = 81
      },
    },
  }
</script>

<style lang="scss" scoped>
  .layout-right-aside {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .todoListWarn {
    flex: 1;
  }

  .system-column {
    padding: 14px 10px 12px 14px;
    border-radius: 4px;
    background: #fff;
    margin-bottom: 10px;

    .system-column-title {
      font-family:
        PingFangSC-Semibold,
        PingFang SC;
      display: flex;
      justify-content: space-between;
      align-items: center;
      word-break: keep-all;

      &-left {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #333;
        font-size: 14px;
      }

      .search-input {
        transition: width ease 0.5s;

        ::v-deep {
          .el-input__inner,
          .el-input-group__append {
            background: #f7f7f7;
            border: none;
            height: 28px;
          }

          .el-input__inner {
            padding: 0;
            text-align: center;
          }

          .el-input-group__append {
            padding: 0 12px 0 0;
          }
        }
      }

      .side-title-icon {
        width: 20px;
        height: 20px;
        margin-right: 2px;
      }
    }

    .system-column-content {
      font-family:
        PingFangSC-Regular,
        PingFang SC;
      font-weight: 400;
      overflow: scroll;
      height: 160px;

      .system-column-item {
        display: flex;
        align-items: center;
        cursor: pointer;

        a {
          display: flex;
          align-items: center;
          width: 100%;
        }

        &--label {
          word-break: keep-all;
          margin-right: 8px;
          padding: 5px 8px;
          border-radius: 4px;
          color: #597ef7;
          background: rgb(89, 126, 247, 0.11);
        }

        &--content {
          color: #202020;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &--content:hover {
          color: #597ef7;
        }
      }

      .system-column-refresh {
        font-family: Helvetica;
        justify-content: center;
        color: #7a7a7a;
      }

      .system-column-refresh:hover {
        color: #000;
      }

      .system-column-item:nth-of-type(1) {
        margin-top: 16px;
      }

      .system-column-item + .system-column-item {
        margin-top: 12px;
      }
    }
  }
</style>
