const defaults = {
  visible: false,
  title: null,
  message: '',
  type: '',
  iconClass: '',
  showInput: false,
  showClose: true,
  modalFade: true,
  lockScroll: true,
  closeOnClickModal: true,
  closeOnPressEscape: true,
  closeOnHashChange: true,
  inputValue: null,
  inputPlaceholder: '',
  inputType: 'text',
  inputPattern: null,
  inputValidator: null,
  inputErrorMessage: '',
  showConfirmButton: true,
  showCancelButton: false,
  confirmButtonPosition: 'right',
  confirmButtonHighlight: false,
  cancelButtonHighlight: false,
  confirmButtonText: '',
  cancelButtonText: '',
  confirmButtonClass: '',
  cancelButtonClass: '',
  customClass: '',
  beforeClose: null,
  dangerouslyUseHTMLString: false,
  center: false,
  roundButton: false,
  distinguishCancelAndClose: false,
}

import Vue from 'vue'
import msgboxVue from './main.vue'
import merge from 'element-ui/src/utils/merge'
import { isVNode } from 'element-ui/src/utils/vdom'

const ECSMessageBoxConstructor = Vue.extend(msgboxVue)
let instance

const defaultCallback = (action) => {
  if (instance.resolve) {
    if (action === 'confirm') {
      instance.resolve(action)
    } else if (instance.reject && (action === 'cancel' || action === 'close')) {
      instance.reject(action)
    }
  }
  delete instance.$slots.default
  document.body.removeChild(instance.$el)
  instance = null
  // const componentInstance = instance.$slots.default[0]?.componentInstance
  // if (componentInstance?.onClose) {
  // componentInstance.onClose(action)
  // }
}

function initInstance() {
  if (instance) {
    instance.$destroy()
  }
  instance = new ECSMessageBoxConstructor({
    el: document.createElement('div'),
  })
  instance.callback = defaultCallback
}

function ECSMessageBox(options) {
  return new Promise((resolve, reject) => {
    if (!instance) {
      initInstance()
    }
    options.resolve = resolve
    options.reject = reject
    for (let prop in options) {
      // eslint-disable-next-line no-prototype-builtins
      if (options.hasOwnProperty(prop)) {
        instance[prop] = options[prop]
      }
    }

    let oldCb = instance.callback
    instance.callback = (action, instance) => {
      oldCb(action, instance)
    }

    if (isVNode(instance.message)) {
      instance.$slots.default = [instance.message]
      instance.message = null
    } else {
      delete instance.$slots.default
    }

    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
      instance.visible = true
    })
  })
}

ECSMessageBox.confirm = function (options) {
  return ECSMessageBox(merge(defaults, { showCancelButton: true }, options))
}

export default ECSMessageBox
export { ECSMessageBox }
