const state = () => ({
  billList: [],
  receiptList: [],
})
const getters = {}
const mutations = {
  SET_BILL_LIST(state, data) {
    state.billList = data
  },
  SET_RECEIPT_LIST(state, data) {
    state.receiptList = data
  },
}
const actions = {
  async FETCH_BILL_LIST({ commit }, item) {
    commit(`SET_BILL_LIST`, item)
  },
  async FETCH_RECEIPT_LIST({ commit }, item) {
    commit(`SET_RECEIPT_LIST`, item)
  },
}
export default { state, getters, mutations, actions }
