import { convertRouter, addButtonRouter } from '@/utils/handleRoutes'
import { constantRoutes, resetRouter } from '@/router'
import { cloneDeep } from 'lodash-es'
const state = () => ({
  routes: [],
  subRoutes: [],
  workflowConfigRoutes: []
})
const getters = {
  routes: (state) => state.routes,
  subRoutes: (state) => state.subRoutes,
  workflowConfigRoutes: (state) => state.workflowConfigRoutes
}
const mutations = {
  setRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes)
  },
  setSubRoutes(state, subRoutes) {
    state.subRoutes = subRoutes
  },
  setWorkflowConfigRoutes(state, workflowConfigRoutes) {
    state.workflowConfigRoutes = workflowConfigRoutes
  },
  clear(state) {
    state.routes = []
    state.subRoutes = []
  },
}
const actions = {
  // 设置所有路由
  setRoutes({ commit }, permissions) {
    // 由于设置的严苛模式，因此需要对该对象进行深拷贝
    const pageRoutes = convertRouter(cloneDeep(permissions))
    const buttonRoutes = addButtonRouter(pageRoutes)
    const routes = pageRoutes.concat(buttonRoutes)
    // 兜底路由
    routes.push({
      path: '*',
      redirect: '404',
      meta: { title: 'not Found', hidden: true },
    })
    commit('setRoutes', routes)
    return routes
  },
  // 设置某个路由下的子路由，比如菜单hover显示的时候
  setSubRoutes({ commit }, subRoutes) {
    commit('setSubRoutes', subRoutes)
  },
  // 不受权限控制，根据工作流配置来动态新增路由
  setWorkflowConfigRoutes({ commit }, workflowConfigRoutes) {
    const pageRoutes = convertRouter(cloneDeep(workflowConfigRoutes.routes))
    const workflowButtonRoutes = addButtonRouter(pageRoutes, workflowConfigRoutes.workflowRoutes)
    commit('setWorkflowConfigRoutes', workflowButtonRoutes)
    return workflowButtonRoutes
  },
  clear({ commit }) {
    commit('clear')
    resetRouter()
  },
}
export default { state, getters, mutations, actions }
