import { reimburseTypeList } from '@/api/budget/reimbursementType'
import { getFeeTypeList } from '@/api/budget/costType'
import { getCurrencyList, getTravelStandardsList, getFeeTypeListByReimburseTypeCode } from '@/api/common'
import { getBudgetOrganizationList, getBudgetProjectCategoryTree, getBudgetProjectList } from '@/api/budget/index'
import { getFeeItemList } from '@/api/budget/costProject'

const state = () => ({
  reimbursementTypeList: [], //报账类型列表
  feeTypeList: [], //上级列表
  budgetOrgList: [], // 预算组织列表
  budgetProjectCategoryTree: [], // 预算项目分类
  budgetProjectList: [], // 预算项目列表
  currencyList: [], // 币种列表
  feeItemList: [], // 费用项列表
  travelStandardsList: [], // 差旅标准列表
  feeTypeOptions: [] // 费用类型列表
})
const getters = {
  currencyList: (state) => state.currencyList,
}

const mutations = {
  SET_REIMBURSEMENT_TYPE_LIST(state, { list }) {
    state.reimbursementTypeList = list
  },
  SET_FEE_TYPE_LIST(state, { list }) {
    state.feeTypeList = list
  },
  SET_BUDGET_ORG_LIST(state, data) {
    state.budgetOrgList = data
  },
  SET_BUDGET_PROJECT_CATEGORY_TREE(state, data) {
    state.budgetProjectCategoryTree = data
  },
  SET_BUDGET_PROJECT_LIST(state, data) {
    state.budgetProjectList = data
  },
  SET_CURRENCY_LIST(state, data) {
    state.currencyList = data
  },
  SET_FEE_ITEM_LIST(state, data) {
    state.feeItemList = data
  },
  SET_TRAVEL_STANDARDS_LIST(state, data) {
    state.travelStandardsList = data
  },
  SET_FEE_TYPE_OPTIONS(state, data) {
    state.feeTypeOptions = data
  },
}
const actions = {
  /**
   * 报账类型列表
   * @param {*} payload
   * @returns
   */
  async FETCH_REIMBURSEMENT_TYPE_LIST({ commit, state }, payload) {
    // 如果存在则不再发起请求
    if (state.reimbursementTypeList.length > 0 && !payload?.isUpdateList) return { data: state.reimbursementTypeList }
    // 发起 http 请求
    const { data: list } = await reimburseTypeList(payload)
    // mutation
    commit(`SET_REIMBURSEMENT_TYPE_LIST`, { list })
    return { data: list }
  },
  /**
   * 费用类型档案
   * @param {*} payload
   * @returns
   */
  async FETCH_FEE_TYPE_LIST({ commit, state }, payload) {
    // 如果存在则不再发起请求
    if (state.feeTypeList.length > 0 && !payload?.isUpdateList) return { data: state.feeTypeList }
    // 发起 http 请求
    const { data: list } = await getFeeTypeList(payload)
    // mutation
    commit(`SET_FEE_TYPE_LIST`, { list })
    return { data: list }
  },
  /**
   * 预算组织列表
   * @param {*} param0
   * @returns
   */
  async FETCH_BUDGET_ORG_LIST({ commit, state }, payload) {
    if (state.budgetOrgList.length > 0 && !payload?.isUpdateList) return { data: state.budgetOrgList }
    const { data } = await getBudgetOrganizationList(payload)
    commit(`SET_BUDGET_ORG_LIST`, data)
    return { data }
  },
  /**
   * 预算项目分类（树形数据）
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async FETCH_BUDGET_PROJECT_CATEGORY_TREE({ commit, state }, payload) {
    if (state.budgetProjectCategoryTree.length > 0 && !payload?.isUpdateList)
      return { data: state.budgetProjectCategoryTree }
    const { data } = await getBudgetProjectCategoryTree(payload)
    commit(`SET_BUDGET_PROJECT_CATEGORY_TREE`, data)
    return { data }
  },
  /**
   * 预算项目列表
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async FETCH_BUDGET_PROJECT_LIST({ commit, state }, payload) {
    if (state.budgetProjectList.length > 0 && !payload?.isUpdateList) return { data: state.budgetProjectList }
    const { data } = await getBudgetProjectList(payload)
    commit(`SET_BUDGET_PROJECT_LIST`, data)
    return { data }
  },
  /**
   * 获取币种列表
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async FETCH_CURRENCY_LIST({ commit, state }, payload) {
    if (state.currencyList.length > 0 && !payload?.isUpdateList) return { data: state.currencyList }
    const { data } = await getCurrencyList(payload)
    commit(`SET_CURRENCY_LIST`, data)
    return { data }
  },
  /**
   * 费用项列表
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async FETCH_FEE_ITEM_LIST({ commit, state }, payload) {
    if (state.feeItemList.length > 0 && !payload?.isUpdateList) return { data: state.feeItemList }
    const { data } = await getFeeItemList(payload)
    commit(`SET_FEE_ITEM_LIST`, data)
    return { data }
  },
  /**
   * 获取差旅标准列表
   * @param {*} param0
   * @param {*} payload
   */
  async FETCH_TRAVEL_STANDARDS_LIST({ commit, state }, payload) {
    if (state.travelStandardsList.length > 0) return { data: state.travelStandardsList }
    const { data } = await getTravelStandardsList(payload)
    commit(`SET_TRAVEL_STANDARDS_LIST`, data)
  },
  /**
   * 费用类型列表
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async FETCH_FEE_TYPE_OPTIONS({ commit, state }, payload) {
    if (state.feeTypeOptions.length > 0 && !payload?.isUpdateList) return { data: state.feeTypeOptions }
    const { data } = await getFeeTypeListByReimburseTypeCode(payload)
    commit(`SET_FEE_TYPE_OPTIONS`, data)
    return { data }
  },
}
export default { state, getters, mutations, actions }
