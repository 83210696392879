const { HttpStatusCode } = require('@/constants/index')
const salt = process.env.X_WEB_SECRET || 'ehdQILITTEK1PXs3PVVngkAE04haa1137g4HHSFgVaRQgU8kyZg9ew88WcAR93uM'
module.exports = {
  salt,
  baseURL: '/',
  /** 消息框消失时间 */
  messageDuration: 3000,
  /** 默认超时时间5分钟 */
  requestTimeout: 30000,
  /** 默认 application/json */
  contentType: 'application/json;charset=UTF-8',
  /** 接口成功返回的code码 */
  successCodes: [HttpStatusCode.SUCCESS, HttpStatusCode.CACHE_SUCCESS],
  /** 忽略接口错误提示的code码 */
  ignoreCodes: [4002630, 4002631],
  /** 需要重新登录的code码 */
  loginBackInCodes: [4011021],
  /**被踢出登录的code码 */
  ejectedFromLoginCode: 4011155,
  /** 缓存数据白名单 */
  cacheWhiteList: ['/mg/system/systemConfig'],
  /** 全局 loading 文本 */
  loadingText: '加急处理中...',
  appId: 'wwc4f875dca83d8f95',
  agentId: '1000101',
  zwsappId: 'ww9170bb6971c80941',
  zwsagentId: '1000057',
  tokenName: 'X-TOKEN',
  klpappId: 'ww00f3efc41b5a433a',
}
