// @ts-nocheck
import Vue from 'vue'
import { TabsBarStorage, ChangePwdStorage, TokenStorage, WebFunnyStorage } from '@/utils/storage'
import { getCookie, removeCookie } from '@/utils/cookie'
import * as UserApi from '@/api/user'
import { getPermissionsAllMenu } from '@/api/common'
import { tokenName } from '@/config'
import { isWXEnv } from '@/utils'

const state = () => ({
  accessToken: process.env.VUE_APP_BASE_LOGIN ? getCookie('x-token') : TokenStorage.get(),
  username: '',
  avatar: '',
  belongId: null,
  employeeName: null,
  employeeNo: null,
  employeeLevel: null,
  permissions: [],
  userInformation: null,
  loginTime: null,
  companyCode: null,
  companyName: null,
  payCompanyCode: null,
  payCompanyName: null,
  loginStaffInfo: null,
  permissionsAllMenus: [],
})
const getters = {
  accessToken: (state) => state.accessToken,
  username: (state) => state.username,
  avatar: (state) => state.avatar,
  belongId: (state) => state.belongId,
  belongCode: (state) => state.belongCode,
  belongName: (state) => state.belongName,
  permissions: (state) => state.permissions,
  userInformation: (state) => state.userInformation,
  employeeId: (state) => state.employeeId,
  employeeName: (state) => state.employeeName,
  employeeNo: (state) => state.employeeNo,
  deptName: (state) => state.deptName,
  loginTime: (state) => state.loginTime,
  companyCode: (state) => state.companyCode,
  companyName: (state) => state.companyName,
  payCompanyCode: (state) => state.payCompanyCode,
  payCompanyName: (state) => state.payCompanyName,
  loginStaffInfo: (state) => state.loginStaffInfo,
  employeeLevel: (state) => state.employeeLevel,
  permissionsAllMenus: (state) => state.permissionsAllMenus,
}
const mutations = {
  removeAccessToken(state) {
    state.accessToken = ''
    TokenStorage.clear()
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    TokenStorage.set(accessToken)
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setPermissionsAllMenus(state, permissionsAllMenus) {
    state.permissionsAllMenus = permissionsAllMenus
  },
  setBelongId(state, belongId) {
    state.belongId = belongId
  },
  setBelongCode(state, belongCode) {
    state.belongCode = belongCode
  },
  setBelongName(state, belongName) {
    state.belongName = belongName
  },
  setEmployeeId(state, employeeId) {
    state.employeeId = employeeId
  },
  setEmployeeNo(state, employeeNo) {
    state.employeeNo = employeeNo
  },
  setEmployeeName(state, employeeName) {
    state.employeeName = employeeName
  },
  clearUserData(state) {
    state.belongId = null
    state.employeeId = null
    state.employeeNo = null
    state.employeeName = null
    state.companyCode = null
    state.companyName = null
    state.payCompanyCode = null
    state.payCompanyName = null
    state.permissions = []
  },
  setUsername(state, username) {
    state.username = username
  },
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  setUserInformation(state, userInformation) {
    state.userInformation = userInformation
  },
  setDeptName(state, deptName) {
    state.deptName = deptName
  },
  setLoginTime(state, loginTime) {
    state.loginTime = loginTime
  },
  setCompanyCode(state, companyCode) {
    state.companyCode = companyCode
  },
  setCompanyName(state, companyName) {
    state.companyName = companyName
  },
  setLoginStaffInfo(state, loginStaffInfo) {
    state.loginStaffInfo = loginStaffInfo
  },
  setEmployeeLevel(state, employeeLevel) {
    state.employeeLevel = employeeLevel
  },
  setPayCompanyInfo(state, { paymentCompanyCode, paymentCompanyName }) {
    state.payCompanyCode = paymentCompanyCode
    state.payCompanyName = paymentCompanyName
  },
}
const actions = {
  setPermissions({ commit }, permissions) {
    commit('setPermissions', permissions)
  },
  setPermissionsAllMenus({ commit }, permissionsAllMenus) {
    commit('setPermissionsAllMenus', permissionsAllMenus)
  },
  removeAccessToken({ commit }) {
    commit('removeAccessToken')
    commit('clearUserData')
    TabsBarStorage.clear()
  },
  async login({ commit }, loginInfo) {
    const callbackType = {
      ECS: 'ECS_LOGIN_USERS',
      FMS: 'DRP_EE_LOGIN_USERS',
    }
    const { data } = await UserApi.login({
      ...loginInfo,
      belongType: (loginInfo?.callbackType && callbackType[loginInfo.callbackType]) || 'ECS_LOGIN_USERS',
    })
    const accessToken = data.token
    if (accessToken) {
      commit('setAccessToken', accessToken)
      ChangePwdStorage.set(data.needUpdatePwd)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好'
      Vue.prototype.$mgNotify(`欢迎登录费控系统`, `${thisTime}！`)
    } else {
      Vue.prototype.$mgMessage(`登录接口异常，未正确返回${tokenName}...`, 'error')
    }
  },
  async getUserInfo({ commit, dispatch, state }) {
    const { data } = await UserApi.getUserInfo(state.accessToken, 'ECS')
    if (!data) return Vue.prototype.$mgMessage('验证失败，请重新登录...', 'error')
    // 写入 webfunny userId
    if (process.env.VUE_APP_WEB_MONITOR_ID) {
      WebFunnyStorage.set({
        userId: data.employeeId,
        userTag: data.belongId,
        projectVersion: '1.0.0',
      })
    }
    const {
      employeeId,
      employeeNo,
      employeeName,
      permissionsMenus,
      belongId,
      belongCode,
      belongName,
      deptName,
      corporateCompanyCode,
      corporateCompanyName,
    } = data
    if (employeeNo && employeeName) {
      commit('setBelongId', belongId)
      commit('setBelongCode', belongCode)
      commit('setBelongName', belongName)
      commit('setPermissions', permissionsMenus)
      commit('setEmployeeId', employeeId)
      commit('setEmployeeNo', employeeNo)
      commit('setEmployeeName', employeeName)
      commit('setUsername', employeeName)
      commit('setUserInformation', data)
      commit('setDeptName', deptName)
      commit('setCompanyCode', corporateCompanyCode)
      commit('setCompanyName', corporateCompanyName)
      commit('setLoginTime', Date.now())
      await dispatch('fetchLoginStaffInfo', [employeeNo])
    }
    dispatch('sys/getZipConfig', {}, { root: true })
  },
  // 获取所有菜单
  async getPermissionsAllMenus({ commit }) {
    const paramsObj = { belongType: 'ECS' }
    const { data } = await getPermissionsAllMenu(paramsObj)
    if (data) {
      commit('setPermissionsAllMenus', data)
    }
  },
  // 修改密码
  async changePassword({ commit }, changePwdInfo) {
    await UserApi.putChangePassWord(changePwdInfo)
    commit('removeAccessToken')
    ChangePwdStorage.clear()
  },
  // 统一清除用户信息
  async clearLoginInfo({ dispatch }, isToLogin) {
    await dispatch('resetMemoryAccessToken')
    dispatch('removeAccessToken')
    dispatch('routes/clear', {}, { root: true })
    dispatch('tabsBar/clear', {}, { root: true })
    removeCookie('X-TOKEN')
    removeCookie('x-token')
    if (isToLogin) {
      if (isWXEnv) return // 跳转逻辑在401页面处理
      if (!process.env?.VUE_APP_BASE_LOGIN) return (location.href = `/#/login`)
      location.href = `${process.env.VUE_APP_BASE_LOGIN}?callbackUrl=${location.origin}/#/index&callbackType=ECS`
    }
  },
  // 退出登录清空 accessToken 和路由
  async logout({ dispatch }) {
    await UserApi.logout()
    await dispatch('clearLoginInfo', true)
  },
  // 退出登录清空 accessToken 和路由
  async fklogout({ dispatch }) {
    await UserApi.logout()
    await dispatch('clearLoginInfo')
  },
  async fkLogin({ commit }, loginInfo) {
    const { data } = await UserApi.fkLogin({
      ...loginInfo,
    })
    const accessToken = data.token
    if (accessToken) {
      commit('setAccessToken', accessToken)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好'
      Vue.prototype.$mgNotify(`欢迎登录费控系统`, `${thisTime}！`)
    } else {
      Vue.prototype.$mgMessage(`登录接口异常，未正确返回${tokenName}...`, 'error')
    }
  },
  async wxLogin({ commit }, { code, channel }) {
    const { data } = await UserApi.wxAuthLogin(code, channel)
    const accessToken = data.token
    if (accessToken) {
      commit('setAccessToken', accessToken)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好'
      Vue.prototype.$mgNotify(`欢迎登录费控系统`, `${thisTime}！`)
    } else {
      Vue.prototype.$mgMessage(`登录接口异常，未正确返回${tokenName}...`, 'error')
    }
  },
  // 重置缓存的token和已缓存的路由
  resetMemoryAccessToken({ commit }) {
    commit('setPermissions', [])
    commit('setPermissionsAllMenus', [])
    commit('setAccessToken', '')
  },
  // 登录人的员工信息
  async fetchLoginStaffInfo({ commit }, staffCodes) {
    const { data } = await UserApi.getMdStaffList({ staffCodes })
    commit('setLoginStaffInfo', data)
    commit('setEmployeeLevel', (data?.length > 0 && data[0]?.postLevelName) || null)
    // 根据部门中心编码查询付款公司
    if (data[0]?.deptOrgOneCode) {
      const { data: payCompanyInfo } = await UserApi.getPayCompanyByDeptCenterCode(data[0].deptOrgOneCode)
      commit('setPayCompanyInfo', payCompanyInfo || {})
    }
    return { data }
  },
}
export default { state, getters, mutations, actions }
