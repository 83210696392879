import Cookies from 'js-cookie'
export function getCookie(name) {
  let result = ''
  let nameEQ = name + '='
  let strArr = document.cookie.split(';') // 把cookie分割成组
  for (let i = 0; i < strArr.length; i++) {
    let str = strArr[i] // 取得字符串
    //去除字符串数组的前面的空格
    while (str.charAt(0) == ' ') {
      // 判断一下字符串有没有前导空格
      str = str.substring(1, str.length) // 有的话，从第二位开始取
    }
    if (str.indexOf(nameEQ) == 0) {
      // 如果含有我们要的name  indexOf -- 查找字符串
      result = window.unescape(str.substring(nameEQ.length, str.length))
      // cookie解码并截取我们要值
    }
  }
  return result
}

export function removeCookie(name) {
  var domain = window.location.host
  document.cookie = `${name}=${getCookie(name)};expires=${new Date(-1)};domain=${process.env.VUE_APP_BASE_DOMAIN}`
  document.cookie = `${name}=${getCookie(name)};expires=${new Date(-1)};domain=${domain}`
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;'
  document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${domain} `
  document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${process.env.VUE_APP_BASE_DOMAIN} `
  Cookies.remove(name)
}
