export interface EventBusType {
  /**
   * 监听事件
   * @param {*} eventName 事件名
   * @param {*} handler 处理事件的回调
   */
  on: (eventName: string, handler: Function) => void
  /**
   * 触发事件
   * @param {*} eventName 事件名
   * @param {*} args 参数
   */
  emit: (eventName: string, args: any) => void
  /**
   * 移除监听事件
   * @param {*} eventName 事件名
   * @param {*} handler 响应事件的回调，不穿则移除全部监听事件
   */
  off: (eventName: string, handler: Function) => void
  /**
   * 触发一次事件之后移除
   * @param {*} eventName 事件名
   * @param {*} handler 处理事件的回调
   */
  once: (eventName: string, handler: Function) => void
  /**
   * 判断当前事件是否已注册
   * @param {*} eventName 事件名
   * @returns Boolean
   */
  has: (eventName: string) => Boolean
}
export default class EventBus {
  
  static map: Map<String, Array<Function>> = new Map()
  
  
  static on(eventName: string, handler: Function) {
    const handlers = this.map.get(eventName)
    if (!handlers) {
      this.map.set(eventName, [handler])
      return
    }
    handlers.push(handler)
  }
  
  static emit(eventName: string, args: any) {
    const handlers = this.map.get(eventName)
    if (handlers?.length) {
      handlers.forEach((fn) => typeof fn === 'function' && fn.call(this, args))
    }
  }

  
  static off(eventName: string, handler: Function) {
    if (!handler) {
      this.map.set(eventName, [])
      return
    }
    const handlers = this.map.get(eventName)
    if (handlers?.length) {
      const index = handlers.findIndex((fn) => fn === handler)
      if (index != -1) handlers.splice(index, 1)
    }
  }

  
  static once(eventName: string, handler:Function) {
    const onceHandlers = (args: any) => {
      this.off(eventName, handler)
      handler.call(this,args)
    }
    this.on(eventName, onceHandlers)
  }

  static has(eventName: string): Boolean {
    const handlers = this.map.get(eventName)
    return Boolean(handlers?.length)
  }
}
