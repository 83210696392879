<template>
  <el-aside class="layout-aside select-none">
    <div class="layout-aside-header">
      <el-avatar :size="60" :src="avatarIcon" class="mt-35" shape="circle" />
      <strong class="flex items-center justify-center">
        {{ employeeName || '--' }}
        <i class="el-icon-message" />
      </strong>
      <p>{{ deptNameLink || '--' }}</p>
    </div>
    <div class="layout-aside-menu">
      <Menu :data="routes" />
    </div>
  </el-aside>
</template>

<script>
  import Menu from '../Menu'
  import { mapState, mapGetters } from 'vuex'
  import avatarIcon from '@/assets/layout/user-avatar.webp'
  export default {
    name: 'Aside',
    components: { Menu },
    data() {
      return {
        avatarIcon,
        deptNameLink: null,
      }
    },
    computed: {
      ...mapState({
        employeeName: (state) => state.user.employeeName,
        loginStaffInfo: (state) => state.user.loginStaffInfo,
      }),
      ...mapGetters({
        routes: 'routes/routes',
      }),
    },
    created() {
      this.deptNameLink = (this.loginStaffInfo?.length > 0 && this.loginStaffInfo[0]?.deptNameLink) || null
    }
  }
</script>

<style lang="scss" scoped>
  .layout-aside {
    width: $mg-menu-collapse-width !important;
    height: inherit;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 11;

    .layout-aside-header {
      width: 100%;
      height: 186px;
      background-image: url(@/assets/layout/aside-header-bg.png);
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;

      strong {
        margin-top: 17px;

        i {
          color: $mg--color-primary;
          margin-left: 10px;
        }
      }

      p {
        margin-top: 18px;
        padding: 0 20px;
        font-size: 14px;
      }
      .el-avatar--circle {
        display: flex;
        justify-content: center;
      }
      .el-avatar {
        background: none;
      }
    }

    .fast-login-entry {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 20px 0;
      position: relative;
      background: linear-gradient(180deg, #fff6f6 0%, #ffffff 100%);

      i {
        font-size: 20px;
      }

      &::after {
        position: absolute;
        bottom: 0;
        content: '';
        width: 80%;
        height: 1px;
        background-color: #e1e5ec;
      }
    }

    .layout-aside-menu {
      flex: 1;
      overflow: auto;
      padding-bottom: 18px;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
</style>
