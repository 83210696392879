import { isEqual } from 'lodash-es'

export default class RequestManager {
  static request = null
  static taskQueue = []

  static setRequest(request) {
    this.request = request
    this.addRequestInterceptor()
    this.addResponseInterceptor()
  }

  static addRequestInterceptor() {
    this.request.interceptors.request.use((config) => {
      if (!config.allowRepeat) {
        this.add(config)
      }
      return config
    })
  }

  static addResponseInterceptor() {
    this.request.interceptors.response.use(
      (response) => {
        const { config } = response
        this.remove(config)
        return response
      },
      (error) => {
        const { config } = error
        if (config) {
          this.remove(config)
        }
        return Promise.reject(error)
      }
    )
  }

  static add(config) {
    const controller = new AbortController()
    config.signal = controller.signal
    if (this.has(config) !== -1) {
      controller.abort()
      console.warn('repeat url:', config.url)
    } else {
      this.taskQueue.push(config)
    }
  }

  static has(config) {
    const { url, method, data, params } = config
    return this.taskQueue.findIndex((oldConfig) => {
      return (
        oldConfig.url === url &&
        oldConfig.method === method &&
        isEqual(oldConfig.data, data) &&
        isEqual(oldConfig.params, params)
      )
    })
  }

  static remove(config) {
    const index = this.has(config)
    if (index !== -1) {
      this.taskQueue.splice(index, 1)
    }
  }
}
